import React, { memo } from 'react'
import { useSelectControl, SelectControlProps } from './useSelectControl'
import styles from './SelectControl.module.scss'

const SelectControl: React.FC<SelectControlProps> = (
  props: SelectControlProps
) => {
  const {
    id,
    name,
    label,
    options,
    defaultValue,
    required,
    disabled,
    onChange,
  } = useSelectControl(props)

  return (
    <label className={styles.label}>
      <select
        id={id}
        name={name}
        defaultValue={defaultValue}
        className={styles.select}
        required={required}
        disabled={disabled}
        onChange={onChange}
      >
        {options.map((value, index) => (
          <option key={`${id}-${index}`} value={value.Id}>
            {value.Name}
          </option>
        ))}
      </select>
      <span className={styles.span}>{label}</span>
    </label>
  )
}

export default memo(SelectControl)
