import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'reducers/rootReducer'
import { getReports } from 'actions/report.action'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { Report } from 'components/Report/useReport'
import { IReport } from 'models/Reports/IReports'

export interface ReportsProps {}

export const useReports = (props: ReportsProps) => {
  const [personalReport, setPersonalReport] = useState<Report>()
  const [familyReports, setFamilyReports] = useState<Report[]>([])

  const reports = useSelector((state: RootState) => state.report.reports)
  const isGetReportsSuccess = useSelector(
    (state: RootState) => state.report.isGetReportsSuccess
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getReports())
  }, [])

  useEffect(() => {
    if (isGetReportsSuccess && reports) {
      const personal: Report = {
        userId: reports.personal.userId,
        reportType: 'primary',
        reportItems: [
          {
            label: 'Name',
            value: reports.personal.name,
          },
          {
            label: 'Allergies',
            value: reports.personal.allergies,
          },
          {
            label: 'Diseases',
            value: reports.personal.diseases,
          },
          {
            label: 'Medications',
            value: reports.personal.medications,
          },
          {
            label: 'Surgeries',
            value: reports.personal.surgeries,
          },
          {
            label: 'Vaccines',
            value: reports.personal.vaccines,
          },
        ],
      }

      const families: Report[] = []

      reports.family.forEach(function (report: IReport) {
        const obj: Report = {
          userId: report.userId,
          reportType: 'secondary',
          reportItems: [
            {
              label: 'Name',
              value: report.name,
            },
            {
              label: 'Allergies',
              value: report.allergies,
            },
            {
              label: 'Diseases',
              value: report.diseases,
            },
            {
              label: 'Medications',
              value: report.medications,
            },
            {
              label: 'Surgeries',
              value: report.surgeries,
            },
            {
              label: 'Vaccines',
              value: report.vaccines,
            },
          ],
        }
        families.push(obj)
      })

      setPersonalReport(personal)
      setFamilyReports(families)
    }
  }, [isGetReportsSuccess])

  return {
    ...props,
    personalReport,
    familyReports,
  }
}
