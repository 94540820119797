import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'reducers/rootReducer'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { deleteAccount, clearAccountStates } from 'actions/account.action'
import { clearAuthStates } from 'actions/auth.action'
import { clearBasicInfoStates } from 'actions/basicInfo.action'
import { clearICEInfoStates } from 'actions/iceInfo.action'
import { clearLookupsStates } from 'actions/lookups.action'
import { clearReportStates } from 'actions/report.action'

export interface HeaderProps {}

export const useHeader = (props: HeaderProps) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [fullName, setFullName] = useState<string>('')
  const [profileImageUrl, setProfileImageUrl] = useState<string | boolean>(
    false
  )

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const isLoading = useSelector((state: RootState) => state.account.isLoading)
  const isDeleteAccountSuccess = useSelector(
    (state: RootState) => state.account.isDeleteAccountSuccess
  )
  const accountInfo = useSelector(
    (state: RootState) => state.account.accountInfo
  )

  const handleDeleteAccountOpen = () => {
    setIsDeleteModalOpen(true)
  }

  const handleDeleteAccountCancel = () => {
    setIsDeleteModalOpen(false)
  }

  const handleDeleteAccountSubmit = () => {
    dispatch(deleteAccount())
  }

  const handleLogout = () => {
    localStorage.clear()

    dispatch(clearAccountStates())
    dispatch(clearAuthStates())
    dispatch(clearBasicInfoStates())
    dispatch(clearICEInfoStates())
    dispatch(clearLookupsStates())
    dispatch(clearReportStates())

    navigate('/Login')
  }

  useEffect(() => {
    if (accountInfo) {
      const { firstName, lastName, imageUrl } = accountInfo
      const accountName = `${firstName || ''} ${lastName || ''}`

      setFullName(accountName)
      setProfileImageUrl(imageUrl || false)
    }
  }, [accountInfo])

  useEffect(() => {
    if (isDeleteAccountSuccess) handleLogout()
  }, [isDeleteAccountSuccess])

  return {
    ...props,
    isDeleteModalOpen,
    isLoading,
    fullName,
    profileImageUrl,
    handleDeleteAccountOpen,
    handleDeleteAccountCancel,
    handleDeleteAccountSubmit,
    handleLogout,
  }
}
