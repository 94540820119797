export interface FeatureRowProps {
  row: Row[]
}

export enum RowTypes {
  Text = 'Text',
  Icon = 'Icon',
}

export interface Row {
  rowType: RowTypes
  rowName?: string
  rowWidth: number
  isChecked?: boolean
  isCentered?: boolean
}

export const useFeatureRow = (props: FeatureRowProps) => {
  return {
    ...props,
  }
}
