import { useState, useEffect } from 'react'
import { TreatingDoctor } from 'screens/BasicInfo/useBasicInfo'

export interface Step3Props {
  isAddMoreDoctorHidden: boolean
  treatingDoctors: TreatingDoctor[]
  setIsAddMoreDoctorHidden: React.Dispatch<React.SetStateAction<boolean>>
  setTreatingDoctors: React.Dispatch<React.SetStateAction<TreatingDoctor[]>>
  setIsErrorExists: React.Dispatch<React.SetStateAction<boolean | undefined>>
  onTreatingDoctorsChange: (
    index: number,
    fieldName: keyof TreatingDoctor,
    value: string
  ) => void
}

export const useStep3 = (props: Step3Props) => {
  const {
    treatingDoctors,
    setTreatingDoctors,
    setIsErrorExists,
    setIsAddMoreDoctorHidden,
    onTreatingDoctorsChange,
  } = props

  const [dialCodeLength, setDialCodeLength] = useState<number | undefined>(3)

  const onPhoneNumberChange = (
    phoneNumber: string,
    index?: number,
    codeLength?: number
  ) => {
    if (index || index === 0)
      onTreatingDoctorsChange(index, 'phoneNumber', phoneNumber)

    setDialCodeLength(codeLength)
  }

  const handleAddMore = () => {
    setTreatingDoctors([
      ...treatingDoctors,
      { name: '', phoneNumber: '', speciality: '' },
    ])
    setIsAddMoreDoctorHidden(true)
  }

  const handleRemove = () => {
    const updatedTreatingDoctors = treatingDoctors.filter((_, i) => i !== 1)
    setTreatingDoctors(updatedTreatingDoctors)
    setIsAddMoreDoctorHidden(false)
  }

  useEffect(() => {
    let phoneNumberError = false
    treatingDoctors.forEach(function (treatingDoctor) {
      if (
        dialCodeLength &&
        treatingDoctor.phoneNumber.length - dialCodeLength < 1
      ) {
        phoneNumberError = true
      }
    })
    setIsErrorExists(phoneNumberError)
  }, [treatingDoctors])

  return {
    ...props,
    onPhoneNumberChange,
    handleAddMore,
    handleRemove,
  }
}
