import React, { memo } from 'react'
import { useStep2, Step2Props } from './useStep2'
import FormControl from 'components/Controls/FormControl/FormControl'
import { FormControlTypes } from 'components/Controls/FormControl/useFormControl'

const Step2: React.FC<Step2Props> = (props: Step2Props) => {
  const {
    isLoading,
    allergies,
    allergiesTypesData,
    otherAllergies,
    setOtherAllergies,
    handleAllergyTypeChange,
  } = useStep2(props)

  return (
    <div className='row'>
      {isLoading
        ? 'Loading...'
        : allergies &&
          allergies.map((allergy) => (
            <div key={allergy.id} className='col-12 col-lg-6 mb-4'>
              <FormControl
                id={`MedicationAllergies_${allergy.id}`}
                name={`MedicationAllergies_${allergy.id}`}
                value={
                  allergiesTypesData.find(
                    (item) => item.allergyTypeId === allergy.id
                  )?.text || ''
                }
                type={FormControlTypes.Textarea}
                placeholder={`${allergy.name} Allergies (optional)`}
                onChange={(e) =>
                  handleAllergyTypeChange(
                    allergy.id,
                    (e.target as HTMLInputElement).value
                  )
                }
              />
            </div>
          ))}

      {!isLoading && (
        <div key='other_allergies' className='col-12 col-lg-6 mb-4'>
          <FormControl
            id='MedicationAllergies_other'
            name='MedicationAllergies_other'
            value={otherAllergies}
            type={FormControlTypes.Textarea}
            placeholder='Other Allergies (optional)'
            onChange={(e) =>
              setOtherAllergies((e.target as HTMLInputElement).value)
            }
          />
        </div>
      )}
    </div>
  )
}

export default memo(Step2)
