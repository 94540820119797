import React from 'react'
import { useReportQRCode, ReportQRCodeProps } from './useReportQRCode'
import { Link } from 'react-router-dom'
import QRCode from 'qrcode.react'
import Report from 'components/Report/Report'
import arrowLeftIcon from 'img/icons/arrow-left.svg'
import styles from './ReportQRCode.module.scss'

const ReportQRCode: React.FC<ReportQRCodeProps> = (
  props: ReportQRCodeProps
) => {
  const { userReport, reportView } = useReportQRCode(props)

  return (
    <main className={styles.report_qrcode_main}>
      <div className='container my-3 my-lg-5'>
        <div className='row mb-4'>
          <div className='col-12 text-center position-relative'>
            <Link to='/' className={styles.btn_back}>
              <img
                src={arrowLeftIcon}
                width={20}
                height={20}
                className='d-block'
              />
            </Link>
            <h1 className={`${styles.title} mb-0 mt-2 mt-lg-0`}>Report</h1>
          </div>
        </div>
        {userReport && (
          <div>
            <div className='row justify-content-center pt-3'>
              <div className='col-md-6 col-lg-5 col-xl-4'>
                Please scan the code below to see <b>{userReport.name}</b> full
                medical report.
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col-md-6 col-lg-5 col-xl-4 d-flex justify-content-center pt-4 pb-5'>
                <div className={styles.qrcode_container}>
                  <QRCode value={userReport.link} size={240} />
                </div>
              </div>
            </div>
            {reportView && (
              <div className='row mb-5'>
                <div className='col-12 mb-2'>
                  <h5 className='fw-bold'>Summary: </h5>
                  <Report report={reportView} />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </main>
  )
}

export default ReportQRCode
