import { useMemo } from 'react'

export interface SubscriptionProps {
  id: string
  type: 'Basic' | 'Family'
  layout: 'Vertical' | 'Horizontal'
  isSelected: boolean
  monthlyPrice: number
  description: string
  priceDescription: string
  minimumPurchases: number
  discount?: number | null
  value?: number
  setValue?: React.Dispatch<React.SetStateAction<number>>
  onClick?: (plan?: 'Basic' | 'Family') => void
}

export const useSubscription = (props: SubscriptionProps) => {
  const { monthlyPrice, discount, isSelected, onClick } = props

  const handleSubscriptionClick = () => {
    if (onClick) onClick()
  }

  const finalPrice: number = useMemo(() => {
    return discount
      ? Number((monthlyPrice - (monthlyPrice * discount) / 100).toFixed(2))
      : monthlyPrice
  }, [discount])

  const finalTotalPrice: number = useMemo(() => {
    return Number((finalPrice * 12).toFixed(2))
  }, [discount])

  return {
    ...props,
    selected: isSelected,
    finalPrice,
    finalTotalPrice,
    handleSubscriptionClick,
  }
}
