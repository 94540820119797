import React, { memo } from 'react'
import { useStep4, Step4Props } from './useStep4'
import FormControl from 'components/Controls/FormControl/FormControl'
import { FormControlTypes } from 'components/Controls/FormControl/useFormControl'
import MobileControl from 'components/Controls/MobileControl/MobileControl'

const Step4: React.FC<Step4Props> = (props: Step4Props) => {
  const {
    ssName,
    ssPhoneNumber,
    ssNumber,
    ssExpiryDate,
    setSSName,
    setSSNumber,
    setSSExpiryDate,
    onPhoneNumberChange,
  } = useStep4(props)

  return (
    <div className='row'>
      <div className='col-md-6 mb-4'>
        <FormControl
          id='SSName'
          name='SSName'
          value={ssName}
          type={FormControlTypes.Text}
          placeholder='Name'
          required
          onChange={(e) => setSSName((e.target as HTMLInputElement).value)}
        />
      </div>
      <div className='col-md-6 mb-4'>
        <MobileControl
          label='Phone Number'
          value={ssPhoneNumber}
          onChange={onPhoneNumberChange}
        />
      </div>
      <div className='col-md-6 mb-4'>
        <FormControl
          id='SSNumber'
          name='SSNumber'
          value={ssNumber ? ssNumber : ''}
          type={FormControlTypes.Number}
          placeholder='SSN'
          required
          onChange={(e) =>
            setSSNumber(Number((e.target as HTMLInputElement).value))
          }
        />
      </div>
      <div className='col-md-6 mb-4'>
        <FormControl
          id='SSExpiryDate'
          name='SSExpiryDate'
          value={ssExpiryDate}
          type={FormControlTypes.Date}
          placeholder='Expiry Date'
          required
          onChange={(e) =>
            setSSExpiryDate((e.target as HTMLInputElement).value)
          }
        />
      </div>
    </div>
  )
}

export default memo(Step4)
