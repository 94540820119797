import { Dispatch } from 'redux'
import {
  apiRequest,
  apiFailure,
  getAccountInfo as getAccountInfoReducer,
  updateAccountInfo as updateAccountInfoReducer,
  updateAccountInfoSuccess as updateAccountInfoSuccessReducer,
  deleteAccount as deleteAccountReducer,
  uploadFiles as uploadFilesReducer,
  clearAccountStates as clearAccountStatesReducer,
} from 'reducers/account.reducer'
import { accountService } from 'services/account.service'
import IUpdateAccountInfo from 'models/Account/IUpdateAccountInfo'

export const getAccountInfo = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await accountService.getAccountInfo()
    if (response) {
      const accountInfo = response
      dispatch(getAccountInfoReducer({ accountInfo }))
    }
  } catch (error) {
    dispatch(apiFailure())
  }
}

export const updateAccountInfo =
  (accountInfo: IUpdateAccountInfo) => async (dispatch: Dispatch) => {
    try {
      dispatch(apiRequest())

      const response = await accountService.updateAccountInfo(accountInfo)
      if (response) {
        const accountInfo = response
        dispatch(updateAccountInfoReducer({ accountInfo }))
      }
    } catch (error) {
      dispatch(apiFailure())
    }
  }

export const updateAccountInfoSuccess =
  (success: boolean) => (dispatch: Dispatch) => {
    dispatch(updateAccountInfoSuccessReducer(success))
  }

export const deleteAccount = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await accountService.deleteAccount()
    if (response) {
      dispatch(deleteAccountReducer())
    } else {
      dispatch(apiFailure())
    }
  } catch (error) {
    dispatch(apiFailure())
  }
}

export const uploadFilesAction =
  (formData: FormData) => async (dispatch: Dispatch) => {
    try {
      dispatch(apiRequest())

      const response = await accountService.uploadFiles(formData)
      if (response) {
        const uploadedFiles = response
        dispatch(uploadFilesReducer({ uploadedFiles }))
      } else {
        dispatch(apiFailure())
      }
    } catch (error) {
      dispatch(apiFailure())
    }
  }

export const clearAccountStates = () => (dispatch: Dispatch) => {
  dispatch(clearAccountStatesReducer())
}
