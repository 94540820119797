import React, { memo } from 'react'
import { useFeatureHead, FeatureHeadProps } from './useFeatureHead'

const FeatureHead: React.FC<FeatureHeadProps> = (props: FeatureHeadProps) => {
  const { columns } = useFeatureHead(props)

  return (
    <div className='row mb-3'>
      {columns.map((column, index) => (
        <div
          key={`FeatureHead-${index}`}
          className={`col-${column.columnWidth} ${
            column.isCentered ? 'text-center' : ''
          } fw-bold`}
        >
          {column.columnName}
        </div>
      ))}
    </div>
  )
}

export default memo(FeatureHead)
