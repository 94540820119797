import React, { memo } from 'react'
import { useReport, ReportProps } from './useReport'
import { Link } from 'react-router-dom'
import styles from './Report.module.scss'
import editIcon from 'img/icons/edit.svg'
import scanIcon from 'img/icons/scan.svg'
import eyeIcon from 'img/icons/eye.svg'
import ellipsisIcon from 'img/icons/ellipse.svg'

const Report: React.FC<ReportProps> = (props: ReportProps) => {
  const { report } = useReport(props)

  return (
    <div className={`${styles.report_card} ${styles[report.reportType]}`}>
      {report.reportItems.map((item, index) => (
        <div key={`report-item-${index}`} className={styles.report_item}>
          <div className={styles.label}>{item.label}</div>
          <div className={styles.value}>{item.value}</div>
        </div>
      ))}
      {report.viewAction && report.link ? (
        <div className={`${styles.actions} ${styles.view_mode}`}>
          <Link to={report.link} className={`${styles.btn} ${styles.btn_view}`}>
            <img src={eyeIcon} width={20} height={20} alt='View icon' />
          </Link>
        </div>
      ) : (
        <div className={styles.actions}>
          <button type='button' className={`${styles.btn} ${styles.btn_edit}`}>
            <img src={editIcon} width={20} height={20} alt='Edit icon' />
          </button>
          <span className={styles.ellipsis}>
            <img src={ellipsisIcon} width={4} height={4} alt='Ellipsis icon' />
          </span>
          <Link
            to={`/ReportQRCode/${report.reportType}/${report.userId}`}
            className={`${styles.btn} ${styles.btn_scan}`}
          >
            <img src={scanIcon} width={20} height={20} alt='Scan icon' />
          </Link>
        </div>
      )}
    </div>
  )
}

export default memo(Report)
