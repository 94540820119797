import React, { memo } from 'react'
import {
  useFormControl,
  FormControlProps,
  FormControlTypes,
} from './useFormControl'
import styles from './FormControl.module.scss'
import exclamationIcon from 'img/icons/exclamtion.svg'
import searchIcon from 'img/icons/search.svg'

const FormControl: React.FC<FormControlProps> = (props: FormControlProps) => {
  const {
    type,
    id,
    name,
    placeholder,
    value,
    defaultValue,
    required,
    disabled,
    readOnly,
    rows,
    copyLinkText,
    showErrorMessage,
    errorMessage,
    handleCopy,
    handleKeyDown,
    onKeyUp,
    onChange,
  } = useFormControl(props)

  switch (type) {
    case FormControlTypes.Textarea:
      return (
        <div
          className={`${styles.form_control} ${
            showErrorMessage ? styles.danger : ''
          }`}
        >
          <label className={styles.label}>
            <textarea
              id={id}
              name={name}
              className={styles.input}
              placeholder=' '
              value={value}
              defaultValue={defaultValue}
              required={required}
              disabled={disabled}
              readOnly={readOnly}
              rows={rows || 5}
              onKeyUp={onKeyUp}
              onChange={onChange}
            />
            <span className={styles.span}>{placeholder}</span>
          </label>
        </div>
      )
    case FormControlTypes.Search:
      return (
        <div
          className={`${styles.form_control} ${
            showErrorMessage ? styles.danger : ''
          }`}
        >
          <label className={styles.label}>
            <input
              type={type}
              id={id}
              name={name}
              className={styles.input}
              placeholder=' '
              value={value}
              defaultValue={defaultValue}
              required={required}
              disabled={disabled}
              readOnly={readOnly}
              onKeyUp={onKeyUp}
              onChange={onChange}
            />
            <span className={styles.span}>{placeholder}</span>
            <img
              src={searchIcon}
              width={21}
              height={21}
              loading='lazy'
              className={styles.search_icon}
            />
          </label>
        </div>
      )
    case FormControlTypes.Link:
      return (
        <div
          className={`${styles.form_control} ${
            showErrorMessage ? styles.danger : ''
          }`}
        >
          <label className={styles.label}>
            <input
              type={type}
              id={id}
              name={name}
              className={styles.input}
              placeholder=' '
              value={value}
              defaultValue={defaultValue}
              required={required}
              disabled={disabled}
              readOnly={readOnly}
              onKeyUp={onKeyUp}
              onChange={onChange}
            />
            <span className={styles.span}>{placeholder}</span>
            <span className={styles.copy_link} onClick={handleCopy}>
              {copyLinkText}
            </span>
          </label>
        </div>
      )
    case FormControlTypes.Number:
      return (
        <div
          className={`${styles.form_control} ${
            showErrorMessage ? styles.danger : ''
          }`}
        >
          <label className={styles.label}>
            <input
              type='text'
              id={id}
              name={name}
              className={styles.input}
              placeholder=' '
              value={value}
              defaultValue={defaultValue}
              required={required}
              disabled={disabled}
              readOnly={readOnly}
              onKeyDown={handleKeyDown}
              onKeyUp={onKeyUp}
              onChange={onChange}
            />
            <span className={styles.span}>{placeholder}</span>
          </label>
        </div>
      )
    default:
      return (
        <div
          className={`${styles.form_control} ${
            showErrorMessage ? styles.danger : ''
          }`}
        >
          <label className={styles.label}>
            <input
              type={type}
              id={id}
              name={name}
              className={styles.input}
              placeholder=' '
              value={value}
              defaultValue={defaultValue}
              required={required}
              disabled={disabled}
              readOnly={readOnly}
              onKeyUp={onKeyUp}
              onChange={onChange}
            />
            <span className={styles.span}>{placeholder}</span>
          </label>
          <div className={styles.error_message}>
            <img
              src={exclamationIcon}
              width={25}
              height={25}
              loading='lazy'
              alt='ExclamationIcon'
            />
            {errorMessage}
          </div>
        </div>
      )
  }
}

export default memo(FormControl)
