import { useEffect } from 'react'

export interface Step2Props {
  selectedBloodType: string | null
  otherBloodType: string
  setOtherBloodType: React.Dispatch<React.SetStateAction<string>>
  setSelectedBloodType: React.Dispatch<React.SetStateAction<string | null>>
  setIsErrorExists: React.Dispatch<React.SetStateAction<boolean | undefined>>
}

export const useStep2 = (props: Step2Props) => {
  const {
    selectedBloodType,
    otherBloodType,
    setSelectedBloodType,
    setIsErrorExists,
  } = props

  const bloodGroupTypes = [
    {
      id: '1',
      title: 'A+',
    },
    {
      id: '2',
      title: 'A-',
    },
    {
      id: '3',
      title: 'B+',
    },
    {
      id: '4',
      title: 'B-',
    },
    {
      id: '5',
      title: 'O+',
    },
    {
      id: '6',
      title: 'O-',
    },
    {
      id: '7',
      title: 'AB+',
    },
    {
      id: '8',
      title: 'AB-',
    },
  ]

  const handleBloodTypeClick = (bloodType: string) => {
    setSelectedBloodType(bloodType)
  }

  useEffect(() => {
    if (selectedBloodType) setIsErrorExists(false)
    if (otherBloodType !== '') setIsErrorExists(false)

    if (!selectedBloodType && otherBloodType === '') setIsErrorExists(true)
  }, [selectedBloodType, otherBloodType])

  return {
    ...props,
    bloodGroupTypes,
    handleBloodTypeClick,
  }
}
