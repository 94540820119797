import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { getAccountInfo } from 'actions/account.action'
import { RootState } from 'reducers/rootReducer'

export interface HomeProps {}

export const useHome = (props: HomeProps) => {
  const dispatch = useAppDispatch()
  const accountInfo = useSelector(
    (state: RootState) => state.account.accountInfo
  )

  useEffect(() => {
    if (!accountInfo) dispatch(getAccountInfo())
  }, [accountInfo])

  return {
    ...props,
  }
}
