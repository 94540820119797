import React, { memo } from 'react'
import { useStep7, Step7Props } from './useStep7'
import FormControl from 'components/Controls/FormControl/FormControl'
import { FormControlTypes } from 'components/Controls/FormControl/useFormControl'

const Step7: React.FC<Step7Props> = (props: Step7Props) => {
  const { protheses, setProtheses } = useStep7(props)

  return (
    <div className='row'>
      <div className='col-12 mb-4'>
        <FormControl
          id='Protheses'
          name='Protheses'
          value={protheses}
          type={FormControlTypes.Textarea}
          placeholder='Protheses and Medical Devices (optional)'
          onChange={(e) => setProtheses((e.target as HTMLInputElement).value)}
        />
      </div>
    </div>
  )
}

export default memo(Step7)
