import React, { memo } from 'react'
import {
  useSelectableTagControl,
  SelectableTagControlProps,
} from './useSelectableTagControl'
import styles from './SelectableTagControl.module.scss'

const SelectableTagControl: React.FC<SelectableTagControlProps> = (
  props: SelectableTagControlProps
) => {
  const { selected, id, title, handleClick } = useSelectableTagControl(props)

  return (
    <div
      className={`${styles.selectable_tag} ${selected ? styles.selected : ''}`}
      onClick={() => handleClick(id)}
    >
      <span className={styles.title}>{title}</span>
    </div>
  )
}

export default memo(SelectableTagControl)
