import React, { memo } from 'react'
import { useStep10, Step10Props } from './useStep10'
import FormControl from 'components/Controls/FormControl/FormControl'
import { FormControlTypes } from 'components/Controls/FormControl/useFormControl'
import FileControl from 'components/Controls/FileControl/FileControl'

const Step10: React.FC<Step10Props> = (props: Step10Props) => {
  const {
    selectedVaccines,
    setFiles,
    onVaccineDataChange,
    onVaccineDataRemove,
  } = useStep10(props)
  let isThereSomethingRequired = false

  return (
    <div>
      {selectedVaccines.length
        ? selectedVaccines.map((vaccine, index) => {
            if (vaccine.isCertificateRequired || vaccine.isQrCodeRequired) {
              isThereSomethingRequired = true

              const vaccineId = vaccine.id

              return (
                <div
                  key={`certificates-${vaccineId}-${index}`}
                  className='row mb-4'
                >
                  <div className='col-12 mb-3'>
                    <h5 className='fw-bold'>{vaccine.name}</h5>
                  </div>
                  <div className='col-12 mb-3'>
                    <FormControl
                      id='DateOfVaccine'
                      name='DateOfVaccine'
                      type={FormControlTypes.Date}
                      placeholder='Date of Vaccine'
                      value={vaccine.date as string}
                      required
                      onChange={(e) =>
                        onVaccineDataChange(
                          vaccineId,
                          index,
                          'date',
                          (e.target as HTMLInputElement).value as never
                        )
                      }
                    />
                  </div>
                  {vaccine.isCertificateRequired && (
                    <div className='col-12 mb-3'>
                      <FileControl
                        id={`VaccineCertificate-${vaccineId}-${index}`}
                        name='VaccineCertificate'
                        files={vaccine.certification}
                        setFiles={setFiles}
                        label='Upload the vaccine certificate'
                        showRemoveButton={false}
                        onChange={(e) =>
                          onVaccineDataChange(
                            vaccineId,
                            index,
                            'certification',
                            (e.target as HTMLInputElement).files as never,
                            e as React.ChangeEvent<HTMLInputElement>
                          )
                        }
                        onRemove={(files) =>
                          onVaccineDataRemove(
                            index,
                            'certification',
                            files as never
                          )
                        }
                      />
                    </div>
                  )}
                  {vaccine.isQrCodeRequired && (
                    <div className='col-12 mb-3'>
                      <FileControl
                        id={`VaccineQRCode-${vaccine.id}-${index}`}
                        name='VaccineQRCode'
                        files={vaccine.qrCode}
                        setFiles={setFiles}
                        label='Upload the vaccine QR code'
                        showRemoveButton={false}
                        onChange={(e) =>
                          onVaccineDataChange(
                            vaccineId,
                            index,
                            'qrCode',
                            (e.target as HTMLInputElement).files as never,
                            e as React.ChangeEvent<HTMLInputElement>
                          )
                        }
                        onRemove={(files) =>
                          onVaccineDataRemove(index, 'qrCode', files as never)
                        }
                      />
                    </div>
                  )}
                </div>
              )
            }
          })
        : 'No vaccines certificates or QR codes required, continue.'}

      {!isThereSomethingRequired && selectedVaccines.length
        ? 'No vaccines certificates or QR codes required, continue.'
        : ''}
    </div>
  )
}

export default memo(Step10)
