import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { loginUser } from 'actions/auth.action'
import { isValidPhoneNumber } from 'utils/isValidPhoneNumber'
import { RootState } from 'reducers/rootReducer'
import ILoginWithPhone from 'models/Auth/LoginWithPhone/ILoginWithPhone'

export interface LoginProps {}

enum ErrorMessage {
  Empty = 'Please enter your phone number',
  Invalid = 'Please enter a valid phone number',
}

export const useLogin = (props: LoginProps) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isLoading = useSelector((state: RootState) => state.auth.isLoading)
  const isLoginSuccess = useSelector(
    (state: RootState) => state.auth.isLoginSuccess
  )

  const onChange = (phoneNumber: string) => {
    setPhoneNumber(phoneNumber)
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (phoneNumber.length < 1) {
      setErrorMessage(ErrorMessage.Empty)
      setShowErrorMessage(true)
      return
    }

    if (!isValidPhoneNumber(`+${phoneNumber}`)) {
      setErrorMessage(ErrorMessage.Invalid)
      setShowErrorMessage(true)
      return
    }

    setShowErrorMessage(false)

    const credentials: ILoginWithPhone = {
      phoneNumber: `+${phoneNumber}`,
    }

    try {
      dispatch(loginUser(credentials))
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (isLoginSuccess) navigate('/OTP')
  }, [isLoginSuccess])

  return {
    ...props,
    errorMessage,
    isLoading,
    showErrorMessage,
    onChange,
    onSubmit,
  }
}
