import { useState, useEffect, useCallback } from 'react'

export interface OTPControlProps {
  handleOTPChange: (otp: string) => void
}

export const useOTPControl = (props: OTPControlProps) => {
  const { handleOTPChange } = props

  const [inputs, setInputs] = useState(
    Array.from({ length: 6 }, () => ({ value: '' }))
  )

  const handleInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, parentNode } = e.target
    if (parentNode) {
      const index = Array.from(parentNode.children).indexOf(e.target)

      if (value && index < inputs.length - 1) {
        const nextInput = e.target.nextElementSibling as HTMLInputElement
        if (nextInput) {
          nextInput.focus()
          if (nextInput.value) {
            nextInput.select()
          }
        }
      }

      const newInputs = [...inputs]
      newInputs[index].value = value
      setInputs(newInputs)
    }
  }, [])

  const handlePaste = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault()
      const paste = e.clipboardData.getData('text')

      const newInputs = inputs.map((input, index) => {
        return {
          ...input,
          value: paste[index] || '',
        }
      })

      setInputs(newInputs)
    },
    []
  )

  const handleBackspace = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, parentNode } = e.target
      if (value && parentNode) {
        const index = Array.from(parentNode.children).indexOf(e.target)
        const newInputs = [...inputs]
        newInputs[index].value = ''
        setInputs(newInputs)
        return
      }

      const previousInput = e.target.previousElementSibling as HTMLInputElement
      if (previousInput) {
        previousInput.focus()
      }
    },
    []
  )

  const handleArrowLeft = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const previousInput = e.target
        .previousElementSibling as HTMLInputElement | null
      if (previousInput) {
        previousInput.focus()
      }
    },
    []
  )

  const handleArrowRight = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const nextInput = e.target.nextElementSibling as HTMLInputElement | null
      if (nextInput) {
        nextInput.focus()
      }
    },
    []
  )

  const handleKeyboardEvent = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const syntheticEvent: React.ChangeEvent<HTMLInputElement> = {
        target: e.target as HTMLInputElement,
        currentTarget: e.currentTarget as HTMLInputElement,
        bubbles: e.bubbles,
        cancelable: e.cancelable,
        defaultPrevented: e.defaultPrevented,
        eventPhase: e.eventPhase,
        isTrusted: e.isTrusted,
        nativeEvent: e.nativeEvent,
        persist: e.persist,
        preventDefault: e.preventDefault,
        isDefaultPrevented: e.isDefaultPrevented,
        stopPropagation: e.stopPropagation,
        isPropagationStopped: e.isPropagationStopped,
        timeStamp: e.timeStamp,
        type: 'change',
      }

      switch (e.key) {
        case 'Backspace':
          handleBackspace(syntheticEvent)
          break
        case 'ArrowLeft':
          handleArrowLeft(syntheticEvent)
          break
        case 'ArrowRight':
          handleArrowRight(syntheticEvent)
          break
        default:
          break
      }
    },
    []
  )

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const controlKeys = [8, 9, 13, 35, 36, 37, 39, 46]
      const isControlKey = controlKeys
        .join(',')
        .match(new RegExp(e.which.toString()))
      if (!e.which || (48 <= e.which && e.which <= 57) || isControlKey) {
        return
      } else {
        e.preventDefault()
      }
    },
    []
  )

  useEffect(() => {
    const otp = inputs.map((input) => input.value).join('')
    handleOTPChange(otp)
  }, [inputs, handleOTPChange])

  return {
    inputs,
    handleInput,
    handlePaste,
    handleKeyPress,
    handleKeyboardEvent,
  }
}

export default useOTPControl
