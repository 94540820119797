import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from 'reducers/rootReducer'
import { IReport } from 'models/Reports/IReports'
import { Report } from 'components/Report/useReport'

export interface ReportQRCodeProps {}

export const useReportQRCode = (props: ReportQRCodeProps) => {
  const { reportType, reportId } = useParams<{
    reportType: string
    reportId: string
  }>()

  const [userReport, setUserReport] = useState<IReport | null>(null)
  const [reportView, setReportView] = useState<Report>()

  const reports = useSelector((state: RootState) => state.report.reports)

  useEffect(() => {
    if (reports) {
      if (reportType === 'primary') {
        const personal: Report = {
          userId: reports.personal.userId,
          reportType: 'primary',
          reportItems: [
            {
              label: 'Name',
              value: reports.personal.name,
            },
            {
              label: 'Allergies',
              value: reports.personal.allergies,
            },
            {
              label: 'Diseases',
              value: reports.personal.diseases,
            },
            {
              label: 'Medications',
              value: reports.personal.medications,
            },
            {
              label: 'Surgeries',
              value: reports.personal.surgeries,
            },
            {
              label: 'Vaccines',
              value: reports.personal.vaccines,
            },
          ],
          viewAction: true,
          link: reports.personal.link,
        }

        setUserReport(reports.personal)
        setReportView(personal)
      } else {
        const thisReport = reports.family.find(
          (report) => report.userId === reportId
        )
        if (thisReport) {
          const family: Report = {
            userId: thisReport.userId,
            reportType: 'secondary',
            reportItems: [
              {
                label: 'Name',
                value: thisReport.name,
              },
              {
                label: 'Allergies',
                value: thisReport.allergies,
              },
              {
                label: 'Diseases',
                value: thisReport.diseases,
              },
              {
                label: 'Medications',
                value: thisReport.medications,
              },
              {
                label: 'Surgeries',
                value: thisReport.surgeries,
              },
              {
                label: 'Vaccines',
                value: thisReport.vaccines,
              },
            ],
            viewAction: true,
            link: thisReport.link,
          }

          setUserReport(thisReport)
          setReportView(family)
        }
      }
    }
  }, [reports])

  return {
    ...props,
    userReport,
    reportView,
  }
}
