export interface Step4Props {
  otherDiseases: string
  setOtherDiseases: React.Dispatch<React.SetStateAction<string>>
}

export const useStep4 = (props: Step4Props) => {
  return {
    ...props,
  }
}
