export interface Step8Props {
  isSmoker: boolean
  isDrinker: boolean
  hasContactLens: boolean
  handleBoxChange: (id: string, isSelected: boolean) => void
}

export const useStep8 = (props: Step8Props) => {
  return {
    ...props,
  }
}
