import makeRequest from 'utils/makeRequest'
import {
  DISEASES_ENDPOINTS,
  VACCINES_ENDPOINTS,
  PRODUCTS_ENDPOINTS,
  PLANS_ENDPOINTS,
  ALLERGIES_ENDPOINTS,
} from 'config/apiEndpoints'
import IDiseases from 'models/Diseases/IDiseases'
import IVaccines from 'models/Vaccines/IVaccines'
import IProducts from 'models/Products/IProducts'
import IPlans from 'models/Plans/IPlans'
import IAllergyTypes from 'models/Allergies/IAllergyTypes'

export const lookupsService = {
  getDiseases: async (): Promise<IDiseases> => {
    try {
      const response: IDiseases = await makeRequest(
        'GET',
        DISEASES_ENDPOINTS.GetDiseasesInfo
      )
      return response
    } catch (error) {
      throw new Error('Get diseases info failed')
    }
  },
  getVaccines: async (): Promise<IVaccines> => {
    try {
      const response: IVaccines = await makeRequest(
        'GET',
        VACCINES_ENDPOINTS.GetVaccinesInfo
      )
      return response
    } catch (error) {
      throw new Error('Get vaccines info failed')
    }
  },
  getProducts: async (): Promise<IProducts> => {
    try {
      const response: IProducts = await makeRequest(
        'GET',
        PRODUCTS_ENDPOINTS.GetProducts
      )
      return response
    } catch (error) {
      throw new Error('Get Products failed')
    }
  },
  getPlans: async (): Promise<IPlans> => {
    try {
      const response: IPlans = await makeRequest(
        'GET',
        PLANS_ENDPOINTS.GetPlans
      )
      return response
    } catch (error) {
      throw new Error('Get Plans failed')
    }
  },
  getAllergiesTypes: async (): Promise<IAllergyTypes> => {
    try {
      const response: IAllergyTypes = await makeRequest(
        'GET',
        ALLERGIES_ENDPOINTS.GetAllergyTypes
      )
      return response
    } catch (error) {
      throw new Error('Get Alleries Types failed')
    }
  },
}
