import { useState } from 'react'
import { CountryData } from 'react-phone-input-2'

export interface MobileControlProps {
  label: string
  value?: string
  index?: number
  showErrorMessage?: boolean
  errorMessage?: string
  onChange: (phoneNumber: string, index?: number, dialCode?: number) => void
}

export const useMobileControl = (props: MobileControlProps) => {
  const { index, onChange } = props

  const [dialCodeLength, setDialCodeLength] = useState<number>(3)

  const handlePhoneNumberChange = (phoneNumber: string, data: CountryData) => {
    if (typeof phoneNumber === 'string')
      onChange(phoneNumber, index, data.dialCode.length)

    if (data.dialCode && data.dialCode.length !== dialCodeLength)
      setDialCodeLength(data.dialCode.length)
  }

  return {
    ...props,
    dialCodeLength,
    handlePhoneNumberChange,
  }
}
