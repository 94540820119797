export interface FeatureHeadProps {
  columns: Column[]
}

export interface Column {
  columnName: string
  columnWidth: number
  isCentered?: boolean
}

export const useFeatureHead = (props: FeatureHeadProps) => {
  return {
    ...props,
  }
}
