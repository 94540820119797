import React, { Fragment, memo } from 'react'
import { useStep8, Step8Props } from './useStep8'
import SelectableBoxControl from 'components/Controls/SelectableBoxControl/SelectableBoxControl'

const Step8: React.FC<Step8Props> = (props: Step8Props) => {
  const { isDrinker, isSmoker, hasContactLens, handleBoxChange } =
    useStep8(props)

  return (
    <Fragment>
      <div className='row mb-4'>
        <div className='col-12 mb-2'>
          <h5 className='fw-bold'>Do you Smoke?</h5>
        </div>
        <div className='col-6'>
          <SelectableBoxControl
            id='smokeYes'
            title='Yes'
            isSelected={isSmoker}
            onClick={() => handleBoxChange('isSmoker', true)}
          />
        </div>
        <div className='col-6'>
          <SelectableBoxControl
            id='smokeNo'
            title='No'
            isSelected={!isSmoker}
            onClick={() => handleBoxChange('isSmoker', false)}
          />
        </div>
      </div>
      <div className='row mb-4'>
        <div className='col-12 mb-2'>
          <h5 className='fw-bold'>Do you drink Alcohol?</h5>
        </div>
        <div className='col-6'>
          <SelectableBoxControl
            id='alcoholYes'
            title='Yes'
            isSelected={isDrinker}
            onClick={() => handleBoxChange('isDrinker', true)}
          />
        </div>
        <div className='col-6'>
          <SelectableBoxControl
            id='alcoholNo'
            title='No'
            isSelected={!isDrinker}
            onClick={() => handleBoxChange('isDrinker', false)}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12 mb-2'>
          <h5 className='fw-bold'>Do you wear contact lens?</h5>
        </div>
        <div className='col-6'>
          <SelectableBoxControl
            id='contactLensYes'
            title='Yes'
            isSelected={hasContactLens}
            onClick={() => handleBoxChange('hasContactLens', true)}
          />
        </div>
        <div className='col-6'>
          <SelectableBoxControl
            id='contactLensNo'
            title='No'
            isSelected={!hasContactLens}
            onClick={() => handleBoxChange('hasContactLens', false)}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default memo(Step8)
