import { createSlice } from '@reduxjs/toolkit'
import IReports from 'models/Reports/IReports'

export interface ReportState {
  isLoading: boolean
  isGetReportsSuccess: boolean
  reports: IReports | null
}

const initialState: ReportState = {
  isLoading: false,
  isGetReportsSuccess: false,
  reports: null,
}

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    apiRequest: (state) => {
      state.isLoading = true
    },
    apiFailure: (state) => {
      state.isLoading = false
    },
    getReports(state, action) {
      state.isLoading = false
      state.isGetReportsSuccess = true
      state.reports = action.payload.reports
    },
    clearReportStates(state) {
      state.isLoading = false
      state.isGetReportsSuccess = false
      state.reports = null
    },
  },
})

export const { apiRequest, apiFailure, getReports, clearReportStates } =
  reportSlice.actions
export default reportSlice.reducer
