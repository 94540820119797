import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getVaccines } from 'actions/lookups.action'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { RootState } from 'reducers/rootReducer'
import { Vaccine } from 'models/Vaccines/IVaccines'
import { VaccineWithData } from 'screens/ICEInfo/useICEInfo'

export interface Step9Props {
  vaccinesSearch: string
  debouncedVaccinesSearch: string
  selectedVaccines: string[]
  selectedVaccinesWithData: VaccineWithData[]
  setVaccinesSearch: React.Dispatch<React.SetStateAction<string>>
  setSelectedVaccines: React.Dispatch<React.SetStateAction<string[]>>
  setSelectedVaccinesWithData: React.Dispatch<
    React.SetStateAction<VaccineWithData[]>
  >
}

export const useStep9 = (props: Step9Props) => {
  const {
    debouncedVaccinesSearch,
    selectedVaccines,
    selectedVaccinesWithData,
    setSelectedVaccines,
    setSelectedVaccinesWithData,
  } = props

  const [filteredVaccines, setFilteredVaccines] = useState<Vaccine[]>([])

  const dispatch = useAppDispatch()

  const vaccines: Vaccine[] | null = useSelector(
    (state: RootState) => state.lookups.vaccines
  )
  const isGetVaccinesSuccess = useSelector(
    (state: RootState) => state.lookups.isGetVaccinesSuccess
  )
  const isLoading = useSelector((state: RootState) => state.lookups.isLoading)

  const handleBoxClick = (id?: string, isSelected?: boolean) => {
    if (vaccines)
      if (isSelected && id) {
        const selectedVaccine =
          vaccines.find((vaccine) => vaccine.id === id) || ({} as Vaccine)

        setSelectedVaccines([...selectedVaccines, id])
        setSelectedVaccinesWithData([
          ...selectedVaccinesWithData,
          { ...selectedVaccine, date: '', certification: null, qrCode: null },
        ])
      } else {
        const updatedSelectedVaccines = selectedVaccines.filter(
          (vaccineId) => vaccineId !== id
        )
        const updatedSelectedVaccinesWithData = selectedVaccinesWithData.filter(
          (vaccine) => vaccine.id !== id
        )

        setSelectedVaccines(updatedSelectedVaccines)
        setSelectedVaccinesWithData(updatedSelectedVaccinesWithData)
      }
  }

  useEffect(() => {
    if (vaccines)
      if (debouncedVaccinesSearch === '') setFilteredVaccines(vaccines)
      else {
        const filtered = vaccines.filter((vaccine) =>
          vaccine.name.toLowerCase().includes(debouncedVaccinesSearch)
        )
        setFilteredVaccines(filtered)
      }
  }, [debouncedVaccinesSearch])

  useEffect(() => {
    try {
      if (!vaccines || !vaccines.length) dispatch(getVaccines())
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (isGetVaccinesSuccess && vaccines) setFilteredVaccines(vaccines)
  }, [isGetVaccinesSuccess])

  return {
    ...props,
    vaccines: filteredVaccines,
    isLoading,
    handleBoxClick,
  }
}
