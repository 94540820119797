import { Dispatch } from 'redux'
import {
  apiRequest,
  apiFailure,
  submitICEInfo as submitICEInfoReducer,
  clearICEInfoStates as clearICEStatesReducer,
} from 'reducers/iceInfo.reducer'
import { iceInfoService } from 'services/iceInfo.service'
import ISubmitICEInfo from 'models/OnBoarding/ISubmitICEInfo'

export const submitICEInfo =
  (args: ISubmitICEInfo) => async (dispatch: Dispatch) => {
    try {
      dispatch(apiRequest())

      const response = await iceInfoService.submitICEInfo(args)
      if (response.message) {
        dispatch(submitICEInfoReducer())
      }
    } catch (error) {
      dispatch(apiFailure())
    }
  }

export const clearICEInfoStates = () => (dispatch: Dispatch) => {
  dispatch(clearICEStatesReducer())
}
