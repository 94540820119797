import React, { memo } from 'react'
import { useStep2, Step2Props } from './useStep2'
import SelectableBoxControl from 'components/Controls/SelectableBoxControl/SelectableBoxControl'
import FormControl from 'components/Controls/FormControl/FormControl'
import { FormControlTypes } from 'components/Controls/FormControl/useFormControl'

const Step2: React.FC<Step2Props> = (props: Step2Props) => {
  const {
    bloodGroupTypes,
    selectedBloodType,
    otherBloodType,
    setOtherBloodType,
    handleBloodTypeClick,
  } = useStep2(props)

  return (
    <div className='row'>
      {bloodGroupTypes.map((item, index) => (
        <div
          key={`BloddType-${index}`}
          className='col-6 col-md-4 col-lg-3 mb-3'
        >
          <SelectableBoxControl
            id={item.id}
            title={item.title}
            isSelected={selectedBloodType === item.id}
            onClick={() => handleBloodTypeClick(item.id)}
          />
        </div>
      ))}
      <div className='col-12'>
        <FormControl
          id='OtherBloodType'
          name='OtherBloodType'
          value={otherBloodType}
          type={FormControlTypes.Text}
          placeholder='Other (optional)'
          onChange={(e) =>
            setOtherBloodType((e.target as HTMLInputElement).value)
          }
        />
      </div>
    </div>
  )
}

export default memo(Step2)
