import React from 'react'
import { useStepper, StepperProps } from './useStepper'
import { Link } from 'react-router-dom'
import styles from './Stepper.module.scss'
import arrowLeftIcon from 'img/icons/arrow-left.svg'
import xIcon from 'img/icons/x.svg'

const Stepper: React.FC<StepperProps> = (props: StepperProps) => {
  const {
    steps,
    currentStep,
    isSkipAllowed,
    isCloseAllowed,
    isLastStepCounted,
    showLastPageSubmit,
    isLoading,
    totalSteps,
    progress,
    prevStep,
    nextStep,
    handleSubmit,
  } = useStepper(props)

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.stepper}>
        <div className='d-flex'>
          {currentStep > 0 && (
            <button
              type='button'
              onClick={prevStep}
              className={`${styles.button} ${styles.secondary} me-auto`}
            >
              <img
                src={arrowLeftIcon}
                width={20}
                height={20}
                className='d-block'
              />
            </button>
          )}
          {currentStep < steps.length - 1 && isSkipAllowed && (
            <button
              type='button'
              onClick={nextStep}
              className={`${styles.button} ${styles.secondary} ms-auto`}
            >
              Skip
            </button>
          )}
          {currentStep < steps.length - 1 && isCloseAllowed && (
            <Link
              to='/'
              className={`${styles.button} ${styles.secondary} ms-auto`}
            >
              <img src={xIcon} width={20} height={20} className='d-block' />
            </Link>
          )}
        </div>
        <div
          className={`${styles.progress_bar_container} ${
            !isLastStepCounted && currentStep === steps.length - 1
              ? 'd-none'
              : ''
          }`}
        >
          <div
            style={{
              width: `${progress}%`,
            }}
            className={styles.progress_bar}
          ></div>
        </div>

        <div
          className={`${styles.title_container} ${
            !isLastStepCounted && currentStep === steps.length - 1
              ? 'd-none'
              : ''
          }`}
        >
          <h3 className={styles.title}>{steps[currentStep].title}</h3>
          <span>
            Step {currentStep + 1}/{totalSteps}
          </span>
        </div>

        {steps[currentStep].content.map((item, index) => (
          <React.Fragment key={index}>
            {typeof item === 'string' ? (
              <div dangerouslySetInnerHTML={{ __html: item }} />
            ) : (
              item
            )}
          </React.Fragment>
        ))}

        <div className='text-center'>
          {currentStep < steps.length - 1 || showLastPageSubmit ? (
            <button
              type='submit'
              className={`${styles.button} ${styles.primary}`}
              disabled={isLoading}
            >
              {isLoading ? (
                'Loading...'
              ) : (
                <svg
                  width='28'
                  height='28'
                  viewBox='0 0 28 28'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g clipPath='url(#clip0_44_1528)'>
                    <path
                      d='M10.5 7L17.5 14L10.5 21'
                      stroke='white'
                      strokeWidth='3'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_44_1528'>
                      <rect
                        width='28'
                        height='28'
                        fill='white'
                        transform='translate(28) rotate(90)'
                      />
                    </clipPath>
                  </defs>
                </svg>
              )}
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
    </form>
  )
}

export default Stepper
