import React from 'react'
import { useAddNewReport, AddNewReportProps } from './useAddNewReport'
import styles from './AddNewReport.module.scss'
import plusIcon from 'img/icons/plus.svg'

const AddNewReport: React.FC<AddNewReportProps> = (
  props: AddNewReportProps
) => {
  const {} = useAddNewReport(props)

  return (
    <button className={styles.add_btn}>
      <img
        src={plusIcon}
        alt='plus icon'
        width={16}
        height={16}
        loading='lazy'
      />
    </button>
  )
}

export default AddNewReport
