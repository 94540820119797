export interface Step6Props {
  pastSurgeries: string
  setPastSurgeries: React.Dispatch<React.SetStateAction<string>>
}

export const useStep6 = (props: Step6Props) => {
  return {
    ...props,
  }
}
