import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { submitBasicInfo } from 'actions/basicInfo.action'
import { useAppDispatch } from 'hooks/useAppDispatch'
import ISO8601 from 'types/ISO860'
import ISubmitBasicInfo from 'models/OnBoarding/ISubmitBasicInfo'
import { showAlert } from 'utils/showAlert'
import { validateSSN } from 'utils/validateSSN'
import { RootState } from 'reducers/rootReducer'

export interface BasicInfoProps {}

export interface TreatingDoctor {
  name: string
  phoneNumber: string
  speciality: string
}

export const useBasicInfo = (props: BasicInfoProps) => {
  // Step1 States
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setlastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [dob, setDob] = useState<string>('')
  const [gender, setGender] = useState<string>('')
  const [religion, setReligion] = useState<string>('')
  const [isErrorExistsStep1, setIsErrorExistsStep1] = useState<
    undefined | boolean
  >(true)

  // Step2 States
  const [selectedBloodType, setSelectedBloodType] = useState<string | null>(
    null
  )
  const [otherBloodType, setOtherBloodType] = useState<string>('')
  const [isErrorExistsStep2, setIsErrorExistsStep2] = useState<
    undefined | boolean
  >(true)

  // Step3 States
  const [isAddMoreDoctorHidden, setIsAddMoreDoctorHidden] =
    useState<boolean>(false)
  const [treatingDoctors, setTreatingDoctors] = useState<TreatingDoctor[]>([
    { name: '', phoneNumber: '', speciality: '' },
  ])
  const [isErrorExistsStep3, setIsErrorExistsStep3] = useState<
    undefined | boolean
  >(true)

  // State3 Actions
  const handleTreatingDoctorChange = (
    index: number,
    fieldName: keyof TreatingDoctor,
    value: string
  ) => {
    const updatedTreatingDoctors = [...treatingDoctors]
    updatedTreatingDoctors[index][fieldName] = value
    setTreatingDoctors(updatedTreatingDoctors)
  }

  // Step4 States
  const [ssName, setSSName] = useState<string>('')
  const [ssPhoneNumber, setSSPhoneNumber] = useState<string>('')
  const [ssNumber, setSSNumber] = useState<number | null>(null)
  const [ssExpiryDate, setSSExpiryDate] = useState<string>('')
  const [isErrorExistsStep4, setIsErrorExistsStep4] = useState<
    undefined | boolean
  >(true)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isLoading = useSelector((state: RootState) => state.basicInfo.isLoading)
  const isSubmitBasicInfoSuccess = useSelector(
    (state: RootState) => state.basicInfo.isSubmitBasicInfoSuccess
  )

  const handleSubmitStep1 = () => {
    if (isErrorExistsStep1) {
      showAlert('error', {
        title: 'Please enter a valid date of birth.',
      })
    }
  }

  const handleSubmitStep2 = () => {
    if (isErrorExistsStep2) {
      showAlert('error', {
        title: 'You have to select a blood type.',
      })
    }
  }

  const handleSubmitStep3 = () => {
    if (isErrorExistsStep3) {
      showAlert('error', {
        title: 'Please enter the phone number(s)',
      })
    }
  }

  const handleSubmitBasicInfo = () => {
    if (isErrorExistsStep4) {
      showAlert('error', {
        title: 'Please enter the phone number',
      })
      return
    }

    const checkSSNValidity = validateSSN(ssNumber?.toString() || '')

    if (!checkSSNValidity[0]) {
      showAlert('error', {
        title: checkSSNValidity[1],
      })
      return
    }

    const isoDob = new Date(dob).toISOString() as ISO8601
    const isoExpiryDate = new Date(ssExpiryDate).toISOString() as ISO8601

    const basicInfoArgs: ISubmitBasicInfo = {
      personalData: {
        firstName,
        lastName,
        email,
        dob: isoDob,
        gender,
        religion,
      },
      bloodGroup: selectedBloodType || otherBloodType,
      treatingDoctors,
      insurance: {
        name: ssName,
        phoneNumber: ssPhoneNumber,
        ssn: ssNumber ? ssNumber.toString() : '',
        expiryDate: isoExpiryDate,
      },
    }

    try {
      dispatch(submitBasicInfo(basicInfoArgs))
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmitLastStep = () => {
    navigate('/ICEInfo')
  }

  return {
    ...props,
    firstName,
    lastName,
    email,
    dob,
    gender,
    religion,
    isErrorExistsStep1,
    isAddMoreDoctorHidden,
    selectedBloodType,
    otherBloodType,
    isErrorExistsStep2,
    treatingDoctors,
    isErrorExistsStep3,
    ssName,
    ssPhoneNumber,
    ssNumber,
    ssExpiryDate,
    isErrorExistsStep4,
    isLoading,
    isSubmitBasicInfoSuccess,
    setFirstName,
    setlastName,
    setEmail,
    setDob,
    setGender,
    setReligion,
    setIsErrorExistsStep1,
    setSelectedBloodType,
    setOtherBloodType,
    setIsErrorExistsStep2,
    setTreatingDoctors,
    setIsErrorExistsStep3,
    setIsAddMoreDoctorHidden,
    handleTreatingDoctorChange,
    setSSName,
    setSSPhoneNumber,
    setSSNumber,
    setSSExpiryDate,
    setIsErrorExistsStep4,
    handleSubmitStep1,
    handleSubmitStep2,
    handleSubmitStep3,
    handleSubmitBasicInfo,
    handleSubmitLastStep,
  }
}
