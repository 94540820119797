export interface Step5Props {
  moleculeName: string
  dose: string
  frequency: string
  setMoleculeName: React.Dispatch<React.SetStateAction<string>>
  setDose: React.Dispatch<React.SetStateAction<string>>
  setFrequency: React.Dispatch<React.SetStateAction<string>>
}

export const useStep5 = (props: Step5Props) => {
  return {
    ...props,
  }
}
