import makeRequest from 'utils/makeRequest'
import { getFromLocalStorage } from 'utils/localStorage'
import { ACCOUNT_ENDPOINTS } from 'config/apiEndpoints'
import IAccountInfo from 'models/Account/IAccountInfo'
import IUpdateAccountInfo from 'models/Account/IUpdateAccountInfo'
import IUpdateAccountInfoResponse from 'models/Account/IUpdateAccountInfoResponse'
import IUploadFilesResponse from 'models/Account/IUploadFilesResponse'
import IDeleteAccountResponse from 'models/Account/IDeleteAccountResponse'

export const accountService = {
  getAccountInfo: async (): Promise<IAccountInfo> => {
    try {
      const accessToken = getFromLocalStorage('accessToken')

      const response: IAccountInfo = await makeRequest(
        'GET',
        ACCOUNT_ENDPOINTS.GetAccountInfo,
        undefined,
        accessToken
      )

      return response
    } catch (error) {
      throw new Error('Get account info failed')
    }
  },
  updateAccountInfo: async (
    accountInfo: IUpdateAccountInfo
  ): Promise<IUpdateAccountInfoResponse> => {
    try {
      const accessToken = getFromLocalStorage('accessToken')

      const response: IUpdateAccountInfoResponse = await makeRequest(
        'PUT',
        ACCOUNT_ENDPOINTS.UpdateAccountInfo,
        accountInfo,
        accessToken
      )

      return response
    } catch (error) {
      throw new Error('Update account info failed')
    }
  },
  deleteAccount: async (): Promise<IDeleteAccountResponse> => {
    try {
      const accessToken = getFromLocalStorage('accessToken')

      const response: IDeleteAccountResponse = await makeRequest(
        'DELETE',
        ACCOUNT_ENDPOINTS.DeleteAccount,
        undefined,
        accessToken
      )

      return response
    } catch (error) {
      throw new Error('Delete account failed')
    }
  },
  uploadFiles: async (formData: FormData): Promise<IUploadFilesResponse> => {
    try {
      const accessToken = getFromLocalStorage('accessToken')

      const response: IUploadFilesResponse = await makeRequest(
        'POST',
        ACCOUNT_ENDPOINTS.UploadFiles,
        formData,
        accessToken,
        'multipart/form-data'
      )

      return response
    } catch (error) {
      throw new Error('Upload files failed')
    }
  },
}
