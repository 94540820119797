import React, { memo } from 'react'
import { useStep4, Step4Props } from './useStep4'
import FormControl from 'components/Controls/FormControl/FormControl'
import { FormControlTypes } from 'components/Controls/FormControl/useFormControl'

const Step4: React.FC<Step4Props> = (props: Step4Props) => {
  const { otherDiseases, setOtherDiseases } = useStep4(props)

  return (
    <div className='row'>
      <div className='col-12 mb-4'>
        <FormControl
          id='OtherDiseases'
          name='OtherDiseases'
          value={otherDiseases}
          type={FormControlTypes.Textarea}
          placeholder='Other Diseases (optional)'
          onChange={(e) =>
            setOtherDiseases((e.target as HTMLInputElement).value)
          }
        />
      </div>
    </div>
  )
}

export default memo(Step4)
