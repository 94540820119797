import { useMemo } from 'react'

export interface IncrementDecrementControlProps {
  id: string
  min: number
  max: number
  value: number
  isDisabled?: boolean
  setValue: React.Dispatch<React.SetStateAction<number>>
}

export const useIncrementDecrementControl = (
  props: IncrementDecrementControlProps
) => {
  const { min, max, value, isDisabled, setValue } = props

  const handleIncrement = () => {
    if (value < max) {
      setValue(value + 1)
    }
  }

  const handleDecrement = () => {
    if (value > min) {
      setValue(value - 1)
    }
  }

  const isBtnDecrementDisabled: boolean = useMemo(() => {
    return isDisabled || min === value
  }, [isDisabled, value])

  const isBtnIncrementDisabled: boolean = useMemo(() => {
    return isDisabled || max === value
  }, [isDisabled, value])

  return {
    ...props,
    isBtnDecrementDisabled,
    isBtnIncrementDisabled,
    handleIncrement,
    handleDecrement,
  }
}
