import React, { memo } from 'react'
import { useMobileControl, MobileControlProps } from './useMobileControl'
import PhoneInput from 'react-phone-input-2'
import exclamationIcon from 'img/icons/exclamtion.svg'
import 'react-phone-input-2/lib/material.css'
import './MobileControl.scss'

const MobileControl: React.FC<MobileControlProps> = (
  props: MobileControlProps
) => {
  const {
    label,
    value,
    dialCodeLength,
    showErrorMessage,
    errorMessage,
    handlePhoneNumberChange,
  } = useMobileControl(props)

  return (
    <div
      className={`mobile_control_container ${showErrorMessage ? 'danger' : ''}`}
    >
      <PhoneInput
        country='lb'
        specialLabel={label}
        excludeCountries={['il']}
        enableSearch
        enableAreaCodeStretch
        disableSearchIcon
        countryCodeEditable={false}
        masks={{ lb: '.. ... ...' }}
        value={value}
        onChange={handlePhoneNumberChange}
        buttonClass={
          dialCodeLength === 1
            ? 'one'
            : dialCodeLength === 2
            ? 'two'
            : dialCodeLength === 3
            ? 'three'
            : 'four'
        }
      />
      <div className='error_message'>
        <img
          src={exclamationIcon}
          width={25}
          height={25}
          loading='lazy'
          alt='ExclamationIcon'
        />
        {errorMessage}
      </div>
    </div>
  )
}

export default memo(MobileControl)
