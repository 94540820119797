import React, { memo } from 'react'
import { useOTPControl, OTPControlProps } from './useOTPControl'
import styles from './OTPControl.module.scss'

const OTPControl: React.FC<OTPControlProps> = (props: OTPControlProps) => {
  const {
    inputs,
    handleInput,
    handlePaste,
    handleKeyboardEvent,
    handleKeyPress,
  } = useOTPControl(props)

  return (
    <div className='d-flex'>
      {inputs.map((input, index) => (
        <input
          key={index}
          type='tel'
          maxLength={1}
          pattern='[0-9]'
          required
          title='Only numbers [0-9] are allowed.'
          className={styles.form_control}
          value={input.value}
          onChange={handleInput}
          onPaste={handlePaste}
          onKeyPress={handleKeyPress}
          onKeyUp={(e) => {
            if (e.nativeEvent instanceof KeyboardEvent) {
              const syntheticEvent: React.KeyboardEvent<HTMLInputElement> = {
                ...e,
                target: e.target as HTMLInputElement,
                currentTarget: e.currentTarget as HTMLInputElement,
              }
              handleKeyboardEvent(syntheticEvent)
            }
          }}
        />
      ))}
    </div>
  )
}

export default memo(OTPControl)
