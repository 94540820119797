import React from 'react'
import { useProfile, ProfileProps } from './useProfile'
import './Profile.scss'
import { Link, useParams } from 'react-router-dom'
import arrowLeftIcon from 'img/icons/arrow-left.svg'
import GeneralInformation from './Tabs/GeneralInformation/GeneralInformation'
import PaymentMethod from './Tabs/PaymentMethod/PaymentMethod'
import ReferToFriends from './Tabs/ReferToFriends/ReferToFriends'

const Profile: React.FC<ProfileProps> = (props: ProfileProps) => {
  const {} = useProfile(props)

  const { tab } = useParams<{ tab: string }>()

  return (
    <main className='profile_main'>
      <div className='container my-3 my-lg-5'>
        <div className='row mb-4'>
          <div className='col-12 text-center position-relative'>
            <Link to='/' className='btn-back'>
              <img
                src={arrowLeftIcon}
                width={20}
                height={20}
                className='d-block'
              />
            </Link>
            <h1 className='title mb-0 mt-2 mt-lg-0'>Profile</h1>
          </div>
        </div>
        <div className='d-flex flex-column flex-md-row align-items-start'>
          <div
            className='nav flex-column nav-pills mb-4 me-3'
            id='profile-tabs'
            role='tablist'
            aria-orientation='vertical'
          >
            <button
              className={`nav-link ${
                tab !== 'refer-to-friends' ? 'active' : ''
              }`}
              id='general-information-tab'
              data-bs-toggle='pill'
              data-bs-target='#general-information'
              type='button'
              role='tab'
              aria-controls='general-information'
              aria-selected={tab !== 'refer-to-friends' ? 'true' : 'false'}
            >
              General Information
            </button>
            <button
              className='nav-link'
              id='payment-method-tab'
              data-bs-toggle='pill'
              data-bs-target='#payment-method'
              type='button'
              role='tab'
              aria-controls='payment-method'
              aria-selected='false'
            >
              Payment Method
            </button>
            <button
              className={`nav-link ${
                tab === 'refer-to-friends' ? 'active' : ''
              }`}
              id='refer-to-friends-tab'
              data-bs-toggle='pill'
              data-bs-target='#refer-to-friends'
              type='button'
              role='tab'
              aria-controls='refer-to-friends'
              aria-selected={tab === 'refer-to-friends' ? 'true' : 'false'}
            >
              Refer To Friends
            </button>
          </div>
          <div className='tab-content w-100' id='profile-tabContent'>
            <GeneralInformation tab={tab} />
            <PaymentMethod />
            <ReferToFriends tab={tab} />
          </div>
        </div>
      </div>
    </main>
  )
}

export default Profile
