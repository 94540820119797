import { createSlice } from '@reduxjs/toolkit'
import { getFromLocalStorage, saveToLocalStorage } from 'utils/localStorage'

const LOCAL_STORAGE_PHONE_KEY = 'phoneNumber'
const LOCAL_STORAGE_ACCESSTOKEN_KEY = 'accessToken'
const LOCAL_STORAGE_REFRESHTOKEN_KEY = 'refreshToken'

const storedphoneNumber = getFromLocalStorage(LOCAL_STORAGE_PHONE_KEY) || null
const storedAccessToken =
  getFromLocalStorage(LOCAL_STORAGE_ACCESSTOKEN_KEY) || null
const storedRefreshToken =
  getFromLocalStorage(LOCAL_STORAGE_REFRESHTOKEN_KEY) || null

export interface AuthState {
  phoneNumber: string | null
  accessToken: string | null
  refreshToken: string | null
  isLoading: boolean
  isLoginSuccess: boolean
  isSubmitVerificationCodeSuccess: boolean
  isResendVerificationCodeSuccess: boolean
  isRefreshTokenSuccess: boolean
}

const initialState: AuthState = {
  phoneNumber: storedphoneNumber,
  accessToken: storedAccessToken,
  refreshToken: storedRefreshToken,
  isLoading: false,
  isLoginSuccess: false,
  isSubmitVerificationCodeSuccess: false,
  isResendVerificationCodeSuccess: false,
  isRefreshTokenSuccess: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    apiRequest: (state) => {
      state.isLoading = true
    },
    apiFailure: (state) => {
      state.isLoading = false
    },
    loginSuccess(state, action) {
      state.phoneNumber = action.payload.phoneNumber
      state.isLoading = false
      state.isLoginSuccess = true

      try {
        saveToLocalStorage(LOCAL_STORAGE_PHONE_KEY, action.payload.phoneNumber)
      } catch (error) {
        // Handle the error here
      }
    },
    submitVerificationCode(state, action) {
      state.accessToken = action.payload.accessToken
      state.refreshToken = action.payload.refreshToken
      state.isLoading = false
      state.isSubmitVerificationCodeSuccess = true

      try {
        saveToLocalStorage(
          LOCAL_STORAGE_ACCESSTOKEN_KEY,
          action.payload.accessToken
        )
        saveToLocalStorage(
          LOCAL_STORAGE_REFRESHTOKEN_KEY,
          action.payload.refreshToken
        )
      } catch (error) {
        // Handle the error here
      }
    },
    resendVerificationCode(state) {
      state.isLoading = false
      state.isResendVerificationCodeSuccess = true
    },
    refreshToken(state, action) {
      state.accessToken = action.payload.accessToken
      state.refreshToken = action.payload.refreshToken
      state.isLoading = false
      state.isRefreshTokenSuccess = true

      try {
        saveToLocalStorage(
          LOCAL_STORAGE_ACCESSTOKEN_KEY,
          action.payload.accessToken
        )
        saveToLocalStorage(
          LOCAL_STORAGE_REFRESHTOKEN_KEY,
          action.payload.refreshToken
        )
      } catch (error) {
        // Handle the error here
      }
    },
    clearAuthStates(state) {
      state.phoneNumber = null
      state.accessToken = null
      state.refreshToken = null
      state.isLoading = false
      state.isLoginSuccess = false
      state.isSubmitVerificationCodeSuccess = false
      state.isResendVerificationCodeSuccess = false
      state.isRefreshTokenSuccess = false
    },
  },
})

export const {
  apiRequest,
  apiFailure,
  loginSuccess,
  submitVerificationCode,
  resendVerificationCode,
  refreshToken,
  clearAuthStates,
} = authSlice.actions
export default authSlice.reducer
