import makeRequest from 'utils/makeRequest'
import { getFromLocalStorage } from 'utils/localStorage'
import { ONBOARDING_ENDPOINTS } from 'config/apiEndpoints'
import ISubmitICEInfo from 'models/OnBoarding/ISubmitICEInfo'
import ISubmitICEInfoResponse from 'models/OnBoarding/ISubmitICEInfoResponse'

export const iceInfoService = {
  submitICEInfo: async (
    params: ISubmitICEInfo
  ): Promise<ISubmitICEInfoResponse> => {
    try {
      const accessToken = getFromLocalStorage('accessToken')

      const response: ISubmitICEInfoResponse = await makeRequest(
        'POST',
        ONBOARDING_ENDPOINTS.SubmitICEInfot,
        params,
        accessToken
      )
      return response
    } catch (error) {
      throw new Error('Submit ICE info failed')
    }
  },
}
