import { useState } from 'react'
import { VaccineWithData } from 'screens/ICEInfo/useICEInfo'

export interface Step10Props {
  selectedVaccines: VaccineWithData[]
  onVaccineDataChange: (
    vaccineId: string,
    index: number,
    fieldName: keyof VaccineWithData,
    value: never,
    event?: React.ChangeEvent<HTMLInputElement>
  ) => void
  onVaccineDataRemove: (
    index: number,
    fieldName: keyof VaccineWithData,
    value: never
  ) => void
}

export const useStep10 = (props: Step10Props) => {
  const [files, setFiles] = useState<File[]>([])

  return {
    ...props,
    files,
    setFiles,
  }
}
