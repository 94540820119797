import { Dispatch } from 'redux'
import {
  apiRequest,
  apiFailure,
  loginSuccess,
  submitVerificationCode,
  resendVerificationCode,
  refreshToken as refreshTokenReducer,
  clearAuthStates as clearAuthStatesReducer,
} from 'reducers/auth.reducer'
import { authService } from 'services/auth.service'
import ILoginWithPhone from 'models/Auth/LoginWithPhone/ILoginWithPhone'
import ISubmitVerificationCode from 'models/Auth/Verify/ISubmitVerificationCode'
import IResendVerificationCode from 'models/Auth/Others/IResendVerificationCode'
import IRefreshToken from 'models/Auth/Others/IRefreshToken'

export const loginUser =
  (credentials: ILoginWithPhone) => async (dispatch: Dispatch) => {
    try {
      dispatch(apiRequest())

      const response = await authService.login(credentials)
      if (response.message) {
        const { phoneNumber } = credentials
        dispatch(loginSuccess({ phoneNumber }))
      }
    } catch (error) {
      dispatch(apiFailure())
    }
  }

export const verifyCode =
  (args: ISubmitVerificationCode) => async (dispatch: Dispatch) => {
    try {
      dispatch(apiRequest())

      const response = await authService.submitVerificationCode(args)
      if (response.message) {
        const { accessToken, refreshToken } = response
        dispatch(submitVerificationCode({ accessToken, refreshToken }))
      }
    } catch (error) {
      dispatch(apiFailure())
    }
  }

export const resendCode =
  (args: IResendVerificationCode) => async (dispatch: Dispatch) => {
    try {
      dispatch(apiRequest())

      const response = await authService.resendVerificationCode(args)
      if (response.message) {
        dispatch(resendVerificationCode())
      }
    } catch (error) {
      dispatch(apiFailure())
    }
  }

export const refreshToken =
  (args: IRefreshToken) => async (dispatch: Dispatch) => {
    try {
      dispatch(apiRequest())

      const response = await authService.refreshToken(args)
      if (response.accessToken) {
        const { accessToken, refreshToken } = response
        dispatch(refreshTokenReducer({ accessToken, refreshToken }))
      }
    } catch (error) {
      dispatch(apiFailure())
    }
  }

export const clearAuthStates = () => (dispatch: Dispatch) => {
  dispatch(clearAuthStatesReducer())
}
