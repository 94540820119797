import { useRef } from 'react'

export interface FileControlProps {
  id: string
  name: string
  label: string
  files: File[] | null
  required?: boolean
  multiple?: boolean
  allowedTypes?: string[]
  showRemoveButton?: boolean
  setFiles: React.Dispatch<React.SetStateAction<File[]>>
  onChange?: (e: React.ChangeEvent) => void
  onRemove?: (files: File[]) => void
}

enum AddFilesMethods {
  Click,
  Drop,
}

export const useFileControl = (props: FileControlProps) => {
  const { files, multiple, allowedTypes, setFiles, onChange, onRemove } = props

  const fileInputRef = useRef<HTMLInputElement>(null)
  const acceptTypes = allowedTypes?.join(',')

  const handleZoneClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    const { files: droppedFiles } = event.dataTransfer
    addFiles(droppedFiles, AddFilesMethods.Drop)
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files: selectedFiles } = event.target
    addFiles(selectedFiles, AddFilesMethods.Click)

    if (onChange) onChange(event)
  }

  const handleRemoveFile = (
    event: React.MouseEvent<HTMLButtonElement> | undefined,
    fileIndex: number
  ) => {
    if (event) event.stopPropagation()
    const index = Number(fileIndex)
    removeFile(index)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const removeFile = (index: number) => {
    if (files) {
      const updatedFiles = [...files]
      updatedFiles.splice(index, 1)
      setFiles(updatedFiles)

      if (onRemove) onRemove(updatedFiles)
    }
  }

  const addFiles = (addedFiles: FileList | null, method: AddFilesMethods) => {
    if (files)
      if (addedFiles && addedFiles.length > 0) {
        const newFiles = Array.from(addedFiles)
        const uniqueFiles = newFiles
          .filter((file) => {
            if (allowedTypes && allowedTypes.length > 0) {
              const fileType = file.type
              return allowedTypes.includes(fileType)
            }
            return true
          })
          .filter(
            (file) =>
              !files.some((existingFile) => existingFile.name === file.name)
          )
        if (multiple) setFiles((prevFiles) => [...prevFiles, ...uniqueFiles])
        else {
          switch (method) {
            case AddFilesMethods.Click:
              setFiles(uniqueFiles)
              break
            case AddFilesMethods.Drop:
              setFiles(uniqueFiles.slice(0, 1))
          }
        }
      }
  }

  return {
    ...props,
    fileInputRef,
    acceptTypes,
    files,
    handleDragOver,
    handleDrop,
    handleZoneClick,
    handleFileChange,
    handleRemoveFile,
  }
}
