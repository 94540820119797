import { createSlice } from '@reduxjs/toolkit'
import IAccountInfo from 'models/Account/IAccountInfo'
import IUploadFilesResponse from 'models/Account/IUploadFilesResponse'

export interface AccountState {
  isLoading: boolean
  isGetAccountInfoSuccess: boolean
  isUpdateAccountInfoSuccess: boolean
  isDeleteAccountSuccess: boolean
  isUploadFilesSuccess: boolean
  accountInfo: IAccountInfo | null
  uploadedFiles: IUploadFilesResponse | null
}

const initialState: AccountState = {
  isLoading: false,
  isGetAccountInfoSuccess: false,
  isUpdateAccountInfoSuccess: false,
  isDeleteAccountSuccess: false,
  isUploadFilesSuccess: false,
  accountInfo: null,
  uploadedFiles: null,
}

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    apiRequest: (state) => {
      state.isLoading = true
      state.isGetAccountInfoSuccess = false
      state.isUpdateAccountInfoSuccess = false
      state.isDeleteAccountSuccess = false
      state.isUploadFilesSuccess = false
    },
    apiFailure: (state) => {
      state.isLoading = false
      state.isGetAccountInfoSuccess = false
      state.isUpdateAccountInfoSuccess = false
      state.isDeleteAccountSuccess = false
      state.isUploadFilesSuccess = false
    },
    getAccountInfo(state, action) {
      state.isLoading = false
      state.accountInfo = action.payload.accountInfo
      state.isGetAccountInfoSuccess = true
    },
    updateAccountInfo(state, action) {
      state.isLoading = false
      state.accountInfo = action.payload.accountInfo
      state.isUpdateAccountInfoSuccess = true
    },
    updateAccountInfoSuccess(state, action) {
      state.isUpdateAccountInfoSuccess = action.payload.success
    },
    deleteAccount(state) {
      state.isLoading = false
      state.isDeleteAccountSuccess = true
    },
    uploadFiles(state, action) {
      state.isLoading = false
      state.uploadedFiles = action.payload.uploadedFiles
      state.isUploadFilesSuccess = true
    },
    clearAccountStates(state) {
      state.isLoading = false
      state.isGetAccountInfoSuccess = false
      state.isUpdateAccountInfoSuccess = false
      state.isDeleteAccountSuccess = false
      state.isUploadFilesSuccess = false
      state.accountInfo = null
      state.uploadedFiles = null
    },
  },
})

export const {
  apiRequest,
  apiFailure,
  getAccountInfo,
  updateAccountInfo,
  updateAccountInfoSuccess,
  deleteAccount,
  uploadFiles,
  clearAccountStates,
} = accountSlice.actions

export default accountSlice.reducer
