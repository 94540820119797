import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { RootState } from 'reducers/rootReducer'
import { getPlans } from 'actions/lookups.action'
import {
  updateAccountInfo,
  updateAccountInfoSuccess,
} from 'actions/account.action'
import { Option } from 'components/Controls/SelectControl/useSelectControl'
import { showAlert } from 'utils/showAlert'
import IUpdateAccountInfo from 'models/Account/IUpdateAccountInfo'
import ISO8601 from 'types/ISO860'

export interface GeneralInformationProps {
  tab?: string
}

export const useGeneralInformation = (props: GeneralInformationProps) => {
  const dispatch = useAppDispatch()
  const [plansOptions, setPlansOptions] = useState<Option[]>([])

  const accountInfo = useSelector(
    (state: RootState) => state.account.accountInfo
  )
  const isLoading = useSelector((state: RootState) => state.account.isLoading)
  const isUpdateAccountInfoSuccess = useSelector(
    (state: RootState) => state.account.isUpdateAccountInfoSuccess
  )
  const plans = useSelector((state: RootState) => state.lookups.plans)?.plans

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)

    const firstName = formData.get('firstName') as string
    const lastName = formData.get('lastName') as string
    const email = formData.get('email') as string
    const dob = formData.get('date') as ISO8601

    const userDob = new Date(dob).getTime()
    const now = new Date().getTime()

    if (userDob > now) {
      showAlert('error', {
        title: 'Wrong DOB!',
        text: "Date of Birth can't be greater than today.",
      })

      return
    }

    const body: IUpdateAccountInfo = {
      firstName,
      lastName,
      email,
      dob,
    }

    dispatch(updateAccountInfo(body))
  }

  useEffect(() => {
    if (!plans) dispatch(getPlans())

    if (plans) {
      const { primary, secondary } = plans

      const options: Option[] = [
        {
          Id: primary.id,
          Name: primary.title,
        },
        {
          Id: secondary.id,
          Name: secondary.title,
        },
      ]

      setPlansOptions(options)
    }
  }, [plans])

  useEffect(() => {
    if (isUpdateAccountInfoSuccess) {
      showAlert('success', {
        title: 'Success!',
        text: 'General Information successfully updated.',
      })

      dispatch(updateAccountInfoSuccess(false))
    }
  }, [isUpdateAccountInfoSuccess])

  return {
    ...props,
    ...accountInfo,
    isLoading,
    plansOptions,
    onSubmit,
  }
}
