import React, { memo } from 'react'
import {
  useSelectableBoxControl,
  SelectableBoxControlProps,
} from './useSelectableBoxControl'
import styles from './SelectableBoxControl.module.scss'

const SelectableBoxControl: React.FC<SelectableBoxControlProps> = (
  props: SelectableBoxControlProps
) => {
  const { id, selected, isUnselectable, title, handleClick } =
    useSelectableBoxControl(props)

  return (
    <div
      className={`${styles.selectable_box} ${selected ? styles.selected : ''} ${
        isUnselectable ? styles.unselectable : ''
      }`}
      onClick={() => handleClick(id)}
    >
      <span className={styles.title}>{title}</span>
    </div>
  )
}

export default memo(SelectableBoxControl)
