import { Dispatch } from 'redux'
import {
  apiRequest,
  apiFailure,
  getDiseases as getDiseasesReducer,
  getVaccines as getVaccinesReducer,
  getProducts as getProductsReducer,
  getPlans as getPlansReducer,
  getAllergiesTypes as getAllergiesTypesReducer,
  clearLookupsStates as clearLookupsStatesReducer,
} from 'reducers/lookups.reducer'
import { lookupsService } from 'services/lookups.service'

export const getDiseases = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await lookupsService.getDiseases()
    if (response) {
      const { diseases } = response
      dispatch(getDiseasesReducer({ diseases }))
    }
  } catch (error) {
    dispatch(apiFailure())
  }
}

export const getVaccines = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await lookupsService.getVaccines()
    if (response) {
      const { vaccines } = response
      dispatch(getVaccinesReducer({ vaccines }))
    }
  } catch (error) {
    dispatch(apiFailure())
  }
}

export const getProducts = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await lookupsService.getProducts()
    if (response) {
      const { products } = response
      dispatch(getProductsReducer({ products }))
    }
  } catch (error) {
    dispatch(apiFailure())
  }
}

export const getPlans = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await lookupsService.getPlans()
    if (response) {
      const plans = response
      dispatch(getPlansReducer({ plans }))
    }
  } catch (error) {
    dispatch(apiFailure())
  }
}

export const getAllergiesTypes = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await lookupsService.getAllergiesTypes()
    if (response) {
      const { allergies } = response
      dispatch(getAllergiesTypesReducer({ allergies }))
    }
  } catch (error) {
    dispatch(apiFailure())
  }
}

export const clearLookupsStates = () => (dispatch: Dispatch) => {
  dispatch(clearLookupsStatesReducer())
}
