import styles from './Button.module.scss'

export interface ButtonProps {
  id: string
  text: string
  type: 'button' | 'submit' | 'reset'
  variant: 'primary' | 'secondary' | 'outline-primary' | 'dashed' | 'link'
  disabled?: boolean
  extraCssClasses?: string
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void
}

export const useButton = (props: ButtonProps) => {
  const { variant, extraCssClasses } = props

  let variantClass

  switch (variant) {
    case 'primary':
      variantClass = styles.btn_primary
      break
    case 'outline-primary':
      variantClass = styles.btn_outline_primary
      break
    case 'secondary':
      variantClass = styles.btn_secondary
      break
    case 'dashed':
      variantClass = styles.btn_dashed
      break
    case 'link':
      variantClass = styles.btn_link
  }

  const cssClasses = `${styles.btn} ${variantClass} ${extraCssClasses}`

  return {
    ...props,
    cssClasses,
  }
}
