import React, { memo } from 'react'
import { useButton, ButtonProps } from './useButton'

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const { id, text, type, disabled, cssClasses, onClick } = useButton(props)

  return (
    <button
      id={id}
      type={type}
      disabled={disabled}
      className={cssClasses}
      onClick={onClick}
    >
      {text}
    </button>
  )
}

export default memo(Button)
