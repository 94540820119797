import { useSelector } from 'react-redux'
import { RootState } from 'reducers/rootReducer'

const useAuth = () => {
  const isAuthenticated = !!useSelector(
    (state: RootState) => state.auth.accessToken
  )

  return {
    isAuthenticated,
  }
}

export default useAuth
