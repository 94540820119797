import React from 'react'
import { useTransfer, TransferProps } from './useTransfer'
import { Link } from 'react-router-dom'
import QRCode from 'qrcode.react'
import arrowLeftIcon from 'img/icons/arrow-left.svg'
import styles from './Transfer.module.scss'

const Transfer: React.FC<TransferProps> = (props: TransferProps) => {
  const { accountInfo } = useTransfer(props)

  return (
    <main className={styles.transfer_main}>
      <div className='container my-3 my-lg-5'>
        <div className='row mb-4'>
          <div className='col-12 text-center position-relative'>
            <Link to='/' className={styles.btn_back}>
              <img
                src={arrowLeftIcon}
                width={20}
                height={20}
                className='d-block'
              />
            </Link>
            <h1 className={`${styles.title} mb-0 mt-2 mt-lg-0`}>QR Code</h1>
          </div>
        </div>
        {accountInfo && (
          <div>
            <div className='row justify-content-center'>
              <div className='col-md-6 col-lg-5 col-xl-4 d-flex justify-content-center pt-4 pb-5'>
                <div className={styles.qrcode_container}>
                  <QRCode value={accountInfo.id} size={240} />
                </div>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col-md-6 col-lg-5 col-xl-4'>
                To transfer a profile from another user, please let the other
                user scan your QR code from their help live app.
              </div>
            </div>
          </div>
        )}
      </div>
    </main>
  )
}

export default Transfer
