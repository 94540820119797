import React, { memo } from 'react'
import { useStep1, Step1Props } from './useStep1'
import FormControl from 'components/Controls/FormControl/FormControl'
import { FormControlTypes } from 'components/Controls/FormControl/useFormControl'

const Step1: React.FC<Step1Props> = (props: Step1Props) => {
  const {
    firstName,
    lastName,
    email,
    dob,
    gender,
    religion,
    setFirstName,
    setLastName,
    setEmail,
    setDob,
    setGender,
    setReligion,
  } = useStep1(props)

  return (
    <div className='row'>
      <div className='col-12 col-lg-6 mb-4'>
        <FormControl
          id='FirstName'
          name='FirstName'
          defaultValue={firstName}
          type={FormControlTypes.Text}
          placeholder='First Name'
          required
          onChange={(e) => setFirstName((e.target as HTMLInputElement).value)}
        />
      </div>
      <div className='col-12 col-lg-6 mb-4'>
        <FormControl
          id='LastName'
          name='LastName'
          defaultValue={lastName}
          type={FormControlTypes.Text}
          placeholder='Last Name'
          required
          onChange={(e) => setLastName((e.target as HTMLInputElement).value)}
        />
      </div>
      <div className='col-12 col-lg-6 mb-4'>
        <FormControl
          id='Email'
          name='Email'
          defaultValue={email}
          type={FormControlTypes.Email}
          placeholder='Email (optional)'
          onChange={(e) => setEmail((e.target as HTMLInputElement).value)}
        />
      </div>
      <div className='col-12 col-lg-6 mb-4'>
        <FormControl
          id='DOB'
          name='DOB'
          defaultValue={dob}
          type={FormControlTypes.Date}
          placeholder='DOB'
          required
          onChange={(e) => setDob((e.target as HTMLInputElement).value)}
        />
      </div>
      <div className='col-12 col-lg-6 mb-4'>
        <FormControl
          id='Gender'
          name='Gender'
          defaultValue={gender}
          type={FormControlTypes.Text}
          placeholder='Gender'
          required
          onChange={(e) => setGender((e.target as HTMLInputElement).value)}
        />
      </div>
      <div className='col-12 col-lg-6 mb-4'>
        <FormControl
          id='Religion'
          name='Religion'
          defaultValue={religion}
          type={FormControlTypes.Text}
          placeholder='Religion / Social Restrictions (optional)'
          onChange={(e) => setReligion((e.target as HTMLInputElement).value)}
        />
      </div>
    </div>
  )
}

export default memo(Step1)
