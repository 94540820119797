import React, { memo } from 'react'
import { useStep3, Step3Props } from './useStep3'
import FormControl from 'components/Controls/FormControl/FormControl'
import { FormControlTypes } from 'components/Controls/FormControl/useFormControl'
import MobileControl from 'components/Controls/MobileControl/MobileControl'
import Button from 'components/Button/Button'

const Step3: React.FC<Step3Props> = (props: Step3Props) => {
  const {
    treatingDoctors,
    isAddMoreDoctorHidden,
    onPhoneNumberChange,
    onTreatingDoctorsChange,
    handleAddMore,
    handleRemove,
  } = useStep3(props)

  return (
    <div>
      {treatingDoctors.map((treatingDoctor, index) => (
        <div key={`TreatingDoctor-${index}`} className='row'>
          <div className='col-12 mb-2'>
            <h6 className='fw-bold'>
              {index === 0 ? 'General Physician' : 'Second Doctor'}
            </h6>
          </div>
          <div className='col-md-6 col-lg-4 mb-4'>
            <FormControl
              id={`TreatingDoctorName-${index}`}
              name='TreatingDoctorName'
              value={treatingDoctor.name}
              type={FormControlTypes.Text}
              placeholder='Name'
              required
              onChange={(e) =>
                onTreatingDoctorsChange(
                  index,
                  'name',
                  (e.target as HTMLInputElement).value
                )
              }
            />
          </div>
          <div className='col-md-6 col-lg-4 mb-4'>
            <MobileControl
              label='Phone Number'
              value={treatingDoctor.phoneNumber}
              index={index}
              onChange={onPhoneNumberChange}
            />
          </div>
          <div className='col-md-6 col-lg-4 mb-4'>
            <FormControl
              id={`TreatingDoctorSpeciality-${index}`}
              name='TreatingDoctorSpeciality'
              value={treatingDoctor.speciality}
              type={FormControlTypes.Text}
              placeholder='Speciality'
              required
              onChange={(e) =>
                onTreatingDoctorsChange(
                  index,
                  'speciality',
                  (e.target as HTMLInputElement).value
                )
              }
            />
          </div>
        </div>
      ))}
      <div className='row'>
        <div className='col-md-4'>
          <Button
            id='AddAnotherDoctor'
            type='button'
            variant='dashed'
            text='Add Another +'
            extraCssClasses={`w-100 ${isAddMoreDoctorHidden ? 'd-none' : ''}`}
            onClick={handleAddMore}
          />
          <Button
            id='RemoveDoctor'
            type='button'
            variant='secondary'
            text='Remove Second Doctor'
            extraCssClasses={`w-100 ${!isAddMoreDoctorHidden ? 'd-none' : ''}`}
            onClick={handleRemove}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(Step3)
