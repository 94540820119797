import React, { Fragment } from 'react'
import { useHome, HomeProps } from './useHome'
import Header from './Header/Header'
import Reports from './Reports/Reports'
import AddNewReport from './AddNewReport/AddNewReport'
import styles from './Home.module.scss'

const Home: React.FC<HomeProps> = (props: HomeProps) => {
  const {} = useHome(props)

  return (
    <Fragment>
      <Header />
      <main className={styles.home_main}>
        <div className='container'>
          <Reports />
          <AddNewReport />
        </div>
      </main>
    </Fragment>
  )
}

export default Home
