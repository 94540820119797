import { useState, useEffect, useMemo } from 'react'

export interface StepperProps {
  isLastStepCounted: boolean
  stepToWait?: number
  isStepSucess?: boolean
  steps: Step[]
}

interface Step {
  title?: string
  content: (JSX.Element | string)[]
  isSkipAllowed?: boolean
  isCloseAllowed?: boolean
  isErrorExists?: boolean
  showLastPageSubmit?: boolean
  isLoading?: boolean
  onClick?: () => void
}

export const useStepper = (props: StepperProps) => {
  const { isLastStepCounted, isStepSucess, stepToWait, steps } = props
  const [currentStep, setCurrentStep] = useState(0)
  const isSkipAllowed = !!steps[currentStep].isSkipAllowed
  const isCloseAllowed = !!steps[currentStep].isCloseAllowed
  const isErrorExists = steps[currentStep].isErrorExists
  const showLastPageSubmit = steps[currentStep].showLastPageSubmit
  const isLoading = steps[currentStep].isLoading

  const progress =
    ((currentStep + 1) /
      (isLastStepCounted ? steps.length : steps.length - 1)) *
    100

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep((prevStep) => prevStep + 1)
    }
  }

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep((prevStep) => prevStep - 1)
    }
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const onClick = steps[currentStep].onClick
    if (onClick) {
      onClick()
    }

    if (stepToWait && currentStep + 1 === stepToWait) return

    if (
      typeof isErrorExists === 'undefined' ||
      (typeof isErrorExists === 'boolean' && !isErrorExists)
    )
      nextStep()
  }

  const totalSteps: number = useMemo(() => {
    return isLastStepCounted ? steps.length : steps.length - 1
  }, [steps])

  useEffect(() => {
    if (isStepSucess) nextStep()
  }, [isStepSucess])

  return {
    ...props,
    currentStep,
    isSkipAllowed,
    isCloseAllowed,
    isLoading,
    showLastPageSubmit,
    totalSteps,
    progress,
    nextStep,
    prevStep,
    handleSubmit,
  }
}
