import React, { memo } from 'react'
import { useStep5, Step5Props } from './useStep5'
import FormControl from 'components/Controls/FormControl/FormControl'
import { FormControlTypes } from 'components/Controls/FormControl/useFormControl'

const Step5: React.FC<Step5Props> = (props: Step5Props) => {
  const {
    moleculeName,
    dose,
    frequency,
    setMoleculeName,
    setDose,
    setFrequency,
  } = useStep5(props)

  return (
    <div className='row'>
      <div className='col-12 col-lg-6 mb-4'>
        <FormControl
          id='MoleculeName'
          name='MoleculeName'
          value={moleculeName}
          type={FormControlTypes.Text}
          placeholder='Molecule Name'
          showErrorMessage
          errorMessage='We need molecule name not medicine name'
          onChange={(e) =>
            setMoleculeName((e.target as HTMLInputElement).value)
          }
        />
      </div>
      <div className='col-12 col-lg-6 mb-4'>
        <FormControl
          id='Dose'
          name='Dose'
          value={dose}
          type={FormControlTypes.Text}
          placeholder='Dose'
          onChange={(e) => setDose((e.target as HTMLInputElement).value)}
        />
      </div>
      <div className='col-12 col-lg-6 mb-4'>
        <FormControl
          id='Frequency'
          name='Frequency'
          value={frequency}
          type={FormControlTypes.Text}
          placeholder='Frequency'
          onChange={(e) => setFrequency((e.target as HTMLInputElement).value)}
        />
      </div>
    </div>
  )
}

export default memo(Step5)
