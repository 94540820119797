export interface ReportProps {
  report: Report
}

export interface Report {
  userId: string
  reportType: string
  reportItems: ReportItem[]
  link?: string
  viewAction?: boolean
}

interface ReportItem {
  label: string
  value: string | number
}

export const useReport = (props: ReportProps) => {
  return {
    ...props,
  }
}
