import React, { memo } from 'react'
import { useStep3, Step3Props } from './useStep3'
import FormControl from 'components/Controls/FormControl/FormControl'
import { FormControlTypes } from 'components/Controls/FormControl/useFormControl'
import SelectableTagControl from 'components/Controls/SelectableTagControl/SelectableTagControl'

const Step3: React.FC<Step3Props> = (props: Step3Props) => {
  const {
    diseases,
    selectedChronicDiseases,
    chronicDiseasesSearch,
    isLoading,
    setChronicDiseasesSearch,
    handleTagClick,
  } = useStep3(props)

  return (
    <div>
      <div className='row'>
        <div className='col-12 mb-4'>
          <FormControl
            id='Diseases'
            name='Diseases'
            value={chronicDiseasesSearch}
            type={FormControlTypes.Search}
            placeholder='Search your Diseases'
            onChange={(e) =>
              setChronicDiseasesSearch((e.target as HTMLInputElement).value)
            }
          />
        </div>
      </div>
      <div className='d-flex flex-wrap gap-2'>
        {isLoading && !diseases
          ? 'Loading...'
          : diseases &&
            diseases.map((disease, index) => (
              <SelectableTagControl
                key={`${disease.id}-${index}`}
                id={disease.id}
                title={disease.name}
                isSelected={selectedChronicDiseases.includes(disease.id)}
                onClick={handleTagClick}
              />
            ))}
      </div>
    </div>
  )
}

export default memo(Step3)
