import Swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2'

interface SweetAlertOptions {
  title?: string
  text?: string
  icon?: SweetAlertIcon
  confirmButtonText?: string
  cancelButtonText?: string
  allowOutsideClick?: boolean
  allowEscapeKey?: boolean
  showConfirmButton?: boolean
  showCancelButton?: boolean
}

let currentSwalInstance: any | null = null

export const showAlert = (
  type: SweetAlertIcon = 'info',
  options: SweetAlertOptions = {}
): Promise<SweetAlertResult> => {
  const {
    title,
    text,
    confirmButtonText,
    cancelButtonText,
    showCancelButton,
    showConfirmButton = true,
    allowOutsideClick = true,
    allowEscapeKey = true,
  } = options

  const swalInstance = Swal.fire({
    icon: type,
    title,
    text,
    showConfirmButton,
    showCancelButton,
    allowOutsideClick,
    allowEscapeKey,
    confirmButtonText: confirmButtonText || 'OK',
    cancelButtonText: cancelButtonText || 'Cancel',
  })

  currentSwalInstance = swalInstance

  return swalInstance
}

showAlert.close = (): void => {
  if (currentSwalInstance) {
    Swal.close()
    currentSwalInstance = null
  }
}
