import React from 'react'
import {
  useGeneralInformation,
  GeneralInformationProps,
} from './useGeneralInformation'
import FormControl from 'components/Controls/FormControl/FormControl'
import SelectControl from 'components/Controls/SelectControl/SelectControl'
import { FormControlTypes } from 'components/Controls/FormControl/useFormControl'
import Button from 'components/Button/Button'
import styles from './GeneralInformation.module.scss'

const GeneralInformation: React.FC<GeneralInformationProps> = (
  props: GeneralInformationProps
) => {
  const {
    tab,
    firstName,
    lastName,
    email,
    dob,
    isLoading,
    plansOptions,
    onSubmit,
  } = useGeneralInformation(props)

  return (
    <div
      className={`tab-pane fade ${
        tab !== 'refer-to-friends' ? 'show active' : ''
      }`}
      id='general-information'
      role='tabpanel'
      aria-labelledby='general-information-tab'
      tabIndex={0}
    >
      <form onSubmit={onSubmit}>
        <div className='row mb-4'>
          <div className='col-lg-6 mb-4'>
            <FormControl
              type={FormControlTypes.Text}
              id='firstName'
              name='firstName'
              placeholder='First Name'
              defaultValue={firstName}
              required
            />
          </div>
          <div className='col-lg-6 mb-4'>
            <FormControl
              type={FormControlTypes.Text}
              id='lastName'
              name='lastName'
              placeholder='Last Name'
              defaultValue={lastName}
              required
            />
          </div>
          <div className='col-lg-6 mb-4'>
            <FormControl
              type={FormControlTypes.Email}
              id='email'
              name='email'
              placeholder='Email'
              defaultValue={email}
              required
            />
          </div>
          <div className='col-lg-6 mb-4'>
            <FormControl
              type={FormControlTypes.Date}
              id='date'
              name='date'
              placeholder='Date Of Birth'
              defaultValue={dob}
              required
            />
          </div>
          <div className='col-lg-6'>
            <SelectControl
              id='activePlan'
              name='activePlan'
              label='Active Plan'
              options={plansOptions}
              required
            />
          </div>
        </div>
        <Button
          type='submit'
          id='btnSubmitGeneralInformation'
          text={isLoading ? 'Loading...' : 'Save'}
          disabled={isLoading}
          variant='primary'
          extraCssClasses={`px-5 ${styles.w_md_100}`}
        />
      </form>
    </div>
  )
}

export default GeneralInformation
