import useAuth from 'hooks/useAuth'

export interface RouterProps {}

export const useRouter = (props: RouterProps) => {
  const { isAuthenticated } = useAuth()

  return {
    ...props,
    isAuthenticated,
  }
}
