import React from 'react'
import { useReferToFriends, ReferToFriendsProps } from './useReferToFriends'
import FormControl from 'components/Controls/FormControl/FormControl'
import { FormControlTypes } from 'components/Controls/FormControl/useFormControl'
import styles from './ReferToFriends.module.scss'

const ReferToFriends: React.FC<ReferToFriendsProps> = (
  props: ReferToFriendsProps
) => {
  const {
    tab,
    link,
    level1Classes,
    level2Classes,
    level3Classes,
    level4Classes,
  } = useReferToFriends(props)

  return (
    <div
      className={`tab-pane fade ${
        tab === 'refer-to-friends' ? 'show active' : ''
      }`}
      id='refer-to-friends'
      role='tabpanel'
      aria-labelledby='refer-to-friends-tab'
      tabIndex={0}
    >
      <div className='row mb-4'>
        <div className='col-12'>
          <FormControl
            type={FormControlTypes.Link}
            id='referralLink'
            name='referralLink'
            placeholder='Referral Link'
            defaultValue={link}
            readOnly
          />
        </div>
      </div>
      <div className={`row ${styles.refer_to_friends_container}`}>
        <div className='col-12'>
          <div className={level4Classes}>
            <span className={styles.point}>.</span>
            <div className={styles.title}>Level 4</div>
            <div>Get 100% discount upon referring 20 friends</div>
          </div>
          <div className={level3Classes}>
            <span className={styles.point}>.</span>
            <div className={styles.title}>Level 3</div>
            <div>Get 75% discount upon referring 15 friends</div>
          </div>
          <div className={level2Classes}>
            <span className={styles.point}>.</span>
            <div className={styles.title}>Level 2</div>
            <div>Get 50% discount upon referring 10 friends</div>
          </div>
          <div className={level1Classes}>
            <span className={styles.point}>.</span>
            <div className={styles.title}>Level 1</div>
            <div>Get 25% discount upon referring 5 friends</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReferToFriends
