import { useState, useEffect } from 'react'
import { useDebounce } from 'hooks/useDebounce'
import { showAlert } from 'utils/showAlert'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useNavigate } from 'react-router-dom'
import { uploadFilesAction } from 'actions/account.action'
import { submitICEInfo } from 'actions/iceInfo.action'
import { RootState } from 'reducers/rootReducer'
import { Allergy } from 'models/OnBoarding/ISubmitICEInfo'
import ISubmitICEInfo from 'models/OnBoarding/ISubmitICEInfo'
import ISO8601 from 'types/ISO860'

export interface ICEInfoProps {}

export interface ICEContact {
  name: string
  phoneNumber: string
}

export interface VaccineWithData {
  id: string
  name: string
  isCertificateRequired: boolean
  isQrCodeRequired: boolean
  date: string | null
  certification: File[] | null
  qrCode: File[] | null
}

export const useICEInfo = (props: ICEInfoProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // Step1 States
  const [isAddMoreContactHidden, setIsAddMoreContactHidden] =
    useState<boolean>(false)
  const [iceContacts, setICEContacts] = useState<ICEContact[]>([
    { name: '', phoneNumber: '' },
  ])
  const [isErrorExistsStep1, setIsErrorExistsStep1] = useState<
    undefined | boolean
  >(true)

  // Step1 Actions
  const handleICEContactChange = (
    index: number,
    fieldName: keyof ICEContact,
    value: string
  ) => {
    const updatedICEContacts = [...iceContacts]
    updatedICEContacts[index][fieldName] = value
    setICEContacts(updatedICEContacts)
  }

  // Step2 States
  const [allergiesTypesData, setAllergiesTypesData] = useState<Allergy[]>([])
  const [otherAllergies, setOtherAllergies] = useState<string>('')

  // Step3 States
  const [chronicDiseasesSearch, setChronicDiseasesSearch] = useState<string>('')
  const [selectedChronicDiseases, setSelectedChronicDiseases] = useState<
    string[]
  >([])
  const debouncedChronicDiseasesSearch = useDebounce<string>(
    chronicDiseasesSearch,
    500
  )

  // Step4 States
  const [otherDiseases, setOtherDiseases] = useState<string>('')

  // Step5 States
  const [moleculeName, setMoleculeName] = useState<string>('')
  const [dose, setDose] = useState<string>('')
  const [frequency, setFrequency] = useState<string>('')

  // Step6 States
  const [pastSurgeries, setPastSurgeries] = useState<string>('')

  // Step7 States
  const [protheses, setProtheses] = useState<string>('')

  // Step8 States
  const [isSmoker, setIsSmoker] = useState<boolean>(false)
  const [isDrinker, setIsDrinker] = useState<boolean>(false)
  const [hasContactLens, setHasContactLens] = useState<boolean>(false)

  // Step8 Actions
  const handleBoxChange = (id: string, isSelected: boolean) => {
    switch (id) {
      case 'isSmoker': {
        setIsSmoker(isSelected)
        break
      }
      case 'isDrinker': {
        setIsDrinker(isSelected)
        break
      }
      case 'hasContactLens': {
        setHasContactLens(isSelected)
        break
      }
    }
  }

  // Step9 States
  const [vaccinesSearch, setVaccinesSearch] = useState<string>('')
  const [selectedVaccines, setSelectedVaccines] = useState<string[]>([])
  const [selectedVaccinesWithData, setSelectedVaccinesWithData] = useState<
    VaccineWithData[]
  >([])
  const debouncedVaccinesSearch = useDebounce<string>(vaccinesSearch, 500)

  const handleSubmitStep1 = () => {
    if (isErrorExistsStep1) {
      showAlert('error', {
        title: 'Please enter the phone number(s)',
      })
    }
  }

  // Step10 States
  const [finalUploadedFiles, setFinalUploadedFiles] = useState<
    {
      vaccineId: string
      data: { certificate?: string; qrCode?: string }
    }[]
  >([])
  const [lastUploadedFileId, setLastUploadedFileId] = useState('')

  // Step10 Actions
  const handleVaccineDataChange = (
    vaccineId: string,
    index: number,
    fieldName: keyof VaccineWithData,
    value: never,
    event?: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updatedVaccinesData = [...selectedVaccinesWithData]
    if (fieldName === 'certification' || fieldName === 'qrCode')
      updatedVaccinesData[index][fieldName] = [...value]
    else updatedVaccinesData[index][fieldName] = value
    setSelectedVaccinesWithData(updatedVaccinesData)

    if (event) {
      const selectedFile = event.target.files && event.target.files[0]
      if (selectedFile) {
        try {
          const fileKey = fieldName === 'qrCode' ? fieldName : 'certificate'
          const formData = new FormData()
          formData.append(fileKey, selectedFile)
          formData.append('vaccineId', vaccineId)

          setLastUploadedFileId(vaccineId)

          dispatch(uploadFilesAction(formData))
        } catch (error) {
          console.error('Error uploading file:', error)
        }
      }
    }
  }

  const handleVaccineDataRemove = (
    index: number,
    fieldName: keyof VaccineWithData,
    value: never
  ) => {
    const updatedVaccinesData = [...selectedVaccinesWithData]
    if (fieldName === 'certification' || fieldName === 'qrCode')
      updatedVaccinesData[index][fieldName] = [...value]
    setSelectedVaccinesWithData(updatedVaccinesData)
  }

  const isLoading = useSelector((state: RootState) => state.iceInfo.isLoading)
  const isUploading = useSelector((state: RootState) => state.account.isLoading)
  const isUploadFilesSuccess = useSelector(
    (state: RootState) => state.account.isUploadFilesSuccess
  )
  const isSubmitICEInfoSuccess = useSelector(
    (state: RootState) => state.iceInfo.isSubmitICEInfoSuccess
  )
  const uploadedFiles = useSelector(
    (state: RootState) => state.account.uploadedFiles
  )

  const handleSubmitICEInfo = () => {
    const finalSelectedVaccines = []

    for (let i = 0; i < selectedVaccinesWithData.length; i++) {
      const vaccineObj = {
        vaccineId: selectedVaccinesWithData[i].id,
        dov: selectedVaccinesWithData[i].date as ISO8601,
        data: {
          qrCodeImage: '',
          certificateImage: '',
        },
      }

      const isVaccineFileAvailable = finalUploadedFiles.find(
        (file) => file.vaccineId === selectedVaccinesWithData[i].id
      )

      if (isVaccineFileAvailable) {
        if (isVaccineFileAvailable.data.certificate)
          vaccineObj.data.certificateImage =
            isVaccineFileAvailable.data.certificate

        if (isVaccineFileAvailable.data.qrCode)
          vaccineObj.data.qrCodeImage = isVaccineFileAvailable.data.qrCode
      }

      finalSelectedVaccines.push(vaccineObj)
    }

    const iceInfoArgs: ISubmitICEInfo = {
      emergency: iceContacts,
      allergies: [...allergiesTypesData, { text: otherAllergies }],
      diseasesIds: selectedChronicDiseases,
      otherDiseases,
      medications: [
        {
          moleculeName,
          dose,
          frequency,
        },
      ],
      surgeries: pastSurgeries,
      medicalDevices: protheses,
      vaccines: finalSelectedVaccines,
      isSmoker,
      isDrinker,
      hasContactLens,
    }

    try {
      dispatch(submitICEInfo(iceInfoArgs))
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (isUploading) {
      showAlert('info', {
        title: 'Uploading',
        text: 'Please wait...',
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      })
      return
    }

    if (isUploadFilesSuccess) {
      if (uploadedFiles) {
        const lastUploadedFile = {
          vaccineId: lastUploadedFileId,
          data: {
            certificate: uploadedFiles.certificate,
            qrCode: uploadedFiles.qrCode,
          },
        }

        const updatedFinalUploadedFiles = [...finalUploadedFiles]

        const existingIndex = updatedFinalUploadedFiles.findIndex(
          (item) => item.vaccineId === lastUploadedFile.vaccineId
        )

        if (existingIndex !== -1) {
          updatedFinalUploadedFiles[existingIndex] = {
            vaccineId: lastUploadedFile.vaccineId,
            data: lastUploadedFile.data,
          }
        } else updatedFinalUploadedFiles.push(lastUploadedFile)

        setFinalUploadedFiles(updatedFinalUploadedFiles)
      }

      showAlert.close()
    }
  }, [isUploading, isUploadFilesSuccess])

  useEffect(() => {
    if (isSubmitICEInfoSuccess) navigate('/')
  }, [isSubmitICEInfoSuccess])

  return {
    ...props,
    isAddMoreContactHidden,
    iceContacts,
    isErrorExistsStep1,
    allergiesTypesData,
    otherAllergies,
    chronicDiseasesSearch,
    selectedChronicDiseases,
    debouncedChronicDiseasesSearch,
    otherDiseases,
    moleculeName,
    dose,
    frequency,
    pastSurgeries,
    protheses,
    isSmoker,
    isDrinker,
    hasContactLens,
    vaccinesSearch,
    debouncedVaccinesSearch,
    isLoading,
    selectedVaccines,
    selectedVaccinesWithData,
    setIsAddMoreContactHidden,
    setICEContacts,
    setIsErrorExistsStep1,
    setAllergiesTypesData,
    setOtherAllergies,
    setChronicDiseasesSearch,
    setSelectedChronicDiseases,
    setOtherDiseases,
    setMoleculeName,
    setDose,
    setFrequency,
    setPastSurgeries,
    setProtheses,
    setVaccinesSearch,
    setSelectedVaccines,
    setSelectedVaccinesWithData,
    handleICEContactChange,
    handleVaccineDataChange,
    handleVaccineDataRemove,
    handleBoxChange,
    handleSubmitStep1,
    handleSubmitICEInfo,
  }
}
