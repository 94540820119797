export interface SwitchControlProps {
  id: string
  name: string
  label: string
  checked: boolean
  required?: boolean
  setChecked: React.Dispatch<React.SetStateAction<boolean>>
}

export const useSwitchControl = (props: SwitchControlProps) => {
  const { setChecked } = props

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked
    setChecked(value)
  }

  return {
    ...props,
    handleChange,
  }
}
