import React, { memo } from 'react'
import { useSwitchControl, SwitchControlProps } from './useSwitchControl'
import styles from './SwitchControl.module.scss'

const SwitchControl: React.FC<SwitchControlProps> = (
  props: SwitchControlProps
) => {
  const { id, name, label, checked, required, handleChange } =
    useSwitchControl(props)

  return (
    <div className={styles.checkbox_container}>
      <input
        id={id}
        name={name}
        type='checkbox'
        checked={checked}
        required={required}
        onChange={handleChange}
        className={styles.checkbox}
      />
      <label htmlFor={id} className={styles.label}>
        {label}
      </label>
    </div>
  )
}

export default memo(SwitchControl)
