import React, { memo } from 'react'
import { useFileControl, FileControlProps } from './useFileControl'
import styles from './FileControl.module.scss'
import uploadIcon from 'img/icons/upload.svg'
import Button from 'components/Button/Button'

const FileControl: React.FC<FileControlProps> = (props: FileControlProps) => {
  const {
    id,
    name,
    required,
    label,
    multiple,
    files,
    fileInputRef,
    acceptTypes,
    showRemoveButton,
    handleDragOver,
    handleDrop,
    handleZoneClick,
    handleFileChange,
    handleRemoveFile,
  } = useFileControl(props)

  return (
    <div className={styles.file_control_container}>
      <label htmlFor={id} className={styles.label}>
        {label}
      </label>
      <div
        className={styles.file_control}
        onClick={handleZoneClick}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <input
          type='file'
          id={id}
          name={name}
          ref={fileInputRef}
          required={required}
          multiple={multiple}
          onChange={handleFileChange}
          className={styles.file_input}
          accept={acceptTypes}
        />
        <div className={styles.file_list}>
          {files &&
            files.map((file, index) => (
              <div key={index} className={styles.file_item}>
                <span className={styles.file_name}>{file.name}</span>
                {showRemoveButton ? (
                  <Button
                    id={`btnRemove-${index}`}
                    type='button'
                    variant='primary'
                    text='Remove'
                    onClick={(event) => handleRemoveFile(event, index)}
                  />
                ) : (
                  ''
                )}
              </div>
            ))}
        </div>
        <div
          className={`${styles.upload_icon_container}${
            files && files.length ? ' d-none' : ''
          }`}
        >
          <img src={uploadIcon} width={30} height={30} loading='lazy' />
          <span className={styles.label}>Upload</span>
        </div>
      </div>
    </div>
  )
}

export default memo(FileControl)
