export const AUTH_ENDPOINTS = {
  Login: {
    LoginWithPhone: 'auth/login/phone',
  },
  Others: {
    RefreshToken: 'auth/refresh-token',
    ResendVerificationCode: 'auth/login/phone/resend',
    Logout: 'auth/logout',
  },
  Verify: {
    SubmitVerificationCode: 'auth/verify/phone',
  },
}

export const ONBOARDING_ENDPOINTS = {
  SubmitBasicInfo: 'account/info/basic',
  SubmitICEInfot: 'account/info/ice',
}

export const ACCOUNT_ENDPOINTS = {
  GetAccountInfo: 'account',
  SaveFCMToken: 'account/fcm',
  UpdateAccountInfo: 'account',
  UploadFiles: 'account/upload',
  ToggleNotificationSwitch: 'account/notification/toggle',
  DeleteAccount: 'account',
  AddFamilyMembers: 'account/add/family',
}

export const PLANS_ENDPOINTS = {
  GetPlans: 'plans',
  GetFeatures: 'plans/features',
}

export const SETTINGS_ENDPOINTS = {
  GetAppSettings: 'settings',
}

export const DISEASES_ENDPOINTS = {
  GetDiseasesInfo: 'diseases',
}

export const VACCINES_ENDPOINTS = {
  GetVaccinesInfo: 'vaccines',
}

export const ALLERGIES_ENDPOINTS = {
  GetAllergyTypes: 'allergies/types',
}

export const REPORTS_ENDPOINTS = {
  GetReports: 'reports',
}

export const PRODUCTS_ENDPOINTS = {
  GetProducts: 'products',
}
