import React, { memo } from 'react'
import { useModal, ModalProps } from './useModal'
import Button from 'components/Button/Button'
import styles from './Modal.module.scss'
import forbiddenIcon from 'img/icons/forbidden.svg'

const Modal: React.FC<ModalProps> = (props: ModalProps) => {
  const {
    id,
    title,
    body,
    closeButtonText,
    submitButtonText,
    isFadingIn,
    isCentered,
    isScrollable,
    isOpen,
    isSubmitDisabled,
    handleTransitionEnd,
    handleOverlayClick,
    onClose,
    onSubmit,
  } = useModal(props)

  return (
    <div
      id={id}
      className={`modal ${styles.modal} ${styles.fade} ${
        isOpen ? styles.show : ''
      } ${isFadingIn ? styles.fade_in : styles.fade_out}`}
      tabIndex={-1}
      aria-labelledby={`${id}Label`}
      aria-hidden={!isOpen}
      aria-modal={isOpen}
      onClick={handleOverlayClick}
      onTransitionEnd={handleTransitionEnd}
    >
      <div
        className={`modal-dialog${isCentered ? ' modal-dialog-centered' : ''}${
          isScrollable ? ' modal-dialog-scrollable' : ''
        }`}
      >
        <div className={styles.modal_content}>
          <div className={styles.modal_header} id={`${id}Header`}>
            <img
              src={forbiddenIcon}
              width={70}
              height={70}
              loading='lazy'
              className='mb-2'
            />
            <h1 className={styles.modal_title} id={`${id}Label`}>
              {title}
            </h1>
          </div>
          <div className={styles.modal_body} id={`${id}Body`}>
            {body}
          </div>
          <div className={styles.modal_footer} id={`${id}Footer`}>
            <Button
              id='btnClose'
              type='button'
              text={closeButtonText}
              variant='outline-primary'
              extraCssClasses='w-50'
              onClick={onClose}
            />
            <Button
              id='btnSubmit'
              type='button'
              text={submitButtonText}
              disabled={isSubmitDisabled}
              variant='primary'
              extraCssClasses='w-50'
              onClick={onSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Modal)
