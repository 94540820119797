import { useSelector } from 'react-redux'
import { RootState } from 'reducers/rootReducer'

export interface TransferProps {}

export const useTransfer = (props: TransferProps) => {
  const accountInfo = useSelector(
    (state: RootState) => state.account.accountInfo
  )

  return {
    ...props,
    accountInfo,
  }
}
