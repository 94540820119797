import apiConfig from 'config/apiConfig'
import { showAlert } from './showAlert'

const makeRequest = async (
  method: string,
  url: string,
  data?: any,
  token?: string,
  contentType?: string
) => {
  try {
    const headers: Record<string, string> = {
      Authorization: `Bearer ${token}`,
    }

    if (contentType) {
      headers['Content-Type'] = contentType
    }

    const response = await apiConfig.request({
      method,
      url,
      data,
      headers,
    })

    if (response.data.error) {
      await showAlert('error', {
        title: 'Error',
        text:
          response.data.error.message ||
          'An error occurred, please try again later.',
      })

      return
    }

    return response.data
  } catch (error) {
    await showAlert('error', {
      title: 'API Error',
      text: 'An error occurred, please try again later.',
    })
  }
}

export default makeRequest
