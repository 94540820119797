import { useState, useEffect } from 'react'

export interface Step4Props {
  ssName: string
  ssPhoneNumber: string
  ssNumber: number | null
  ssExpiryDate: string
  setSSName: React.Dispatch<React.SetStateAction<string>>
  setSSPhoneNumber: React.Dispatch<React.SetStateAction<string>>
  setSSNumber: React.Dispatch<React.SetStateAction<number | null>>
  setSSExpiryDate: React.Dispatch<React.SetStateAction<string>>
  setIsErrorExists: React.Dispatch<React.SetStateAction<boolean | undefined>>
}

export const useStep4 = (props: Step4Props) => {
  const { ssPhoneNumber, setSSPhoneNumber, setIsErrorExists } = props

  const [dialCodeLength, setDialCodeLength] = useState<number | undefined>(3)

  const onPhoneNumberChange = (
    phoneNumber: string,
    index?: number,
    codeLength?: number
  ) => {
    setSSPhoneNumber(phoneNumber)

    setDialCodeLength(codeLength)
  }

  useEffect(() => {
    let phoneNumberError = false
    if (dialCodeLength && ssPhoneNumber.length - dialCodeLength < 1) {
      phoneNumberError = true
    }
    setIsErrorExists(phoneNumberError)
  }, [ssPhoneNumber])

  return {
    ...props,
    onPhoneNumberChange,
  }
}
