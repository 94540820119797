import { createSlice } from '@reduxjs/toolkit'

export interface BasicInfoState {
  isLoading: boolean
  isSubmitBasicInfoSuccess: boolean
}

const initialState: BasicInfoState = {
  isLoading: false,
  isSubmitBasicInfoSuccess: false,
}

const BasicInfoSlice = createSlice({
  name: 'basicInfo',
  initialState,
  reducers: {
    apiRequest: (state) => {
      state.isLoading = true
    },
    apiFailure: (state) => {
      state.isLoading = false
    },
    submitBasicInfo(state) {
      state.isLoading = false
      state.isSubmitBasicInfoSuccess = true
    },
    clearBasicInfoStates(state) {
      state.isLoading = false
      state.isSubmitBasicInfoSuccess = false
    },
  },
})

export const { apiRequest, apiFailure, submitBasicInfo, clearBasicInfoStates } =
  BasicInfoSlice.actions
export default BasicInfoSlice.reducer
