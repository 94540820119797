import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getPlans } from 'actions/lookups.action'
import { Column } from 'components/Features/FeatureHead/useFeatureHead'
import { Row, RowTypes } from 'components/Features/FeatureRow/useFeatureRow'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { RootState } from 'reducers/rootReducer'
import IPlans, { Plan } from 'models/Plans/IPlans'

export interface SubscriptionProps {}

export const useSubscription = (props: SubscriptionProps) => {
  const [familyPlanValue, setFamilyPlanValue] = useState<number>(2)
  const [basicPlanSelected, setBasicPlanSelected] = useState<boolean>(true)
  const [familyPlanSelected, setFamilyPlanSelected] = useState<boolean>(false)
  const [discount, setDiscount] = useState<boolean>(false)
  const [primaryPlan, setPrimaryPlan] = useState<Plan | null>(null)
  const [secondaryPlan, setSecondaryPlan] = useState<Plan | null>(null)

  const dispatch = useAppDispatch()

  const plans: IPlans | null = useSelector(
    (state: RootState) => state.lookups.plans
  )
  const isLoading = useSelector((state: RootState) => state.lookups.isLoading)
  const isGetPlansSuccess = useSelector(
    (state: RootState) => state.lookups.isGetPlansSuccess
  )

  const featureHead: Column[] = [
    {
      columnName: 'Features',
      columnWidth: 8,
    },
    {
      columnName: 'Basic',
      columnWidth: 2,
      isCentered: true,
    },
    {
      columnName: 'Family',
      columnWidth: 2,
      isCentered: true,
    },
  ]

  const featureRows: Row[][] = [
    [
      {
        rowType: RowTypes.Text,
        rowWidth: 8,
        rowName: 'NFC Card',
      },
      {
        rowType: RowTypes.Icon,
        rowWidth: 2,
        isChecked: true,
        isCentered: true,
      },
      {
        rowType: RowTypes.Icon,
        rowWidth: 2,
        isChecked: true,
        isCentered: true,
      },
    ],
    [
      {
        rowType: RowTypes.Text,
        rowWidth: 8,
        rowName: 'Bracelet',
      },
      {
        rowType: RowTypes.Icon,
        rowWidth: 2,
        isChecked: false,
        isCentered: true,
      },
      {
        rowType: RowTypes.Icon,
        rowWidth: 2,
        isChecked: true,
        isCentered: true,
      },
    ],
  ]

  const handleSelectPlan = (plan?: 'Basic' | 'Family') => {
    if (plan === 'Basic') {
      setBasicPlanSelected(true)
      setFamilyPlanSelected(false)
    } else {
      setBasicPlanSelected(false)
      setFamilyPlanSelected(true)
    }
  }

  useEffect(() => {
    try {
      if (!plans) dispatch(getPlans())
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (isGetPlansSuccess && plans) {
      setPrimaryPlan(plans.plans.primary)
      setSecondaryPlan(plans.plans.secondary)
    }
  }, [isGetPlansSuccess])

  return {
    ...props,
    primaryPlan,
    secondaryPlan,
    familyPlanValue,
    featureHead,
    featureRows,
    basicPlanSelected,
    familyPlanSelected,
    discount,
    isLoading,
    setFamilyPlanValue,
    setBasicPlanSelected,
    setFamilyPlanSelected,
    setDiscount,
    handleSelectPlan,
  }
}
