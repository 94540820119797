import React, { memo } from 'react'
import { useStep6, Step6Props } from './useStep6'
import FormControl from 'components/Controls/FormControl/FormControl'
import { FormControlTypes } from 'components/Controls/FormControl/useFormControl'

const Step6: React.FC<Step6Props> = (props: Step6Props) => {
  const { pastSurgeries, setPastSurgeries } = useStep6(props)

  return (
    <div className='row'>
      <div className='col-12 mb-4'>
        <FormControl
          id='Surgeries'
          name='Surgeries'
          value={pastSurgeries}
          type={FormControlTypes.Textarea}
          placeholder='Surgeries (optional)'
          onChange={(e) =>
            setPastSurgeries((e.target as HTMLInputElement).value)
          }
        />
      </div>
    </div>
  )
}

export default memo(Step6)
