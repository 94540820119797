import { Dispatch } from 'redux'
import {
  apiRequest,
  apiFailure,
  submitBasicInfo as submitBasicInfoReducer,
  clearBasicInfoStates as clearBasicInfoStatesReducer,
} from 'reducers/basicInfo.reducer'
import { basicInfoService } from 'services/basicInfo.service'
import ISubmitBasicInfo from 'models/OnBoarding/ISubmitBasicInfo'

export const submitBasicInfo =
  (args: ISubmitBasicInfo) => async (dispatch: Dispatch) => {
    try {
      dispatch(apiRequest())

      const response = await basicInfoService.submitBasicInfo(args)
      if (response.message) {
        dispatch(submitBasicInfoReducer())
      }
    } catch (error) {
      dispatch(apiFailure())
    }
  }

export const clearBasicInfoStates = () => (dispatch: Dispatch) => {
  dispatch(clearBasicInfoStatesReducer())
}
