import React from 'react'
import { useICEInfo, ICEInfoProps } from './useICEInfo'
import Stepper from 'components/Stepper/Stepper'
import Step1 from './Steps/Step1/Step1'
import Step2 from './Steps/Step2/Step2'
import Step3 from './Steps/Step3/Step3'
import Step4 from './Steps/Step4/Step4'
import Step5 from './Steps/Step5/Step5'
import Step6 from './Steps/Step6/Step6'
import Step7 from './Steps/Step7/Step7'
import Step8 from './Steps/Step8/Step8'
import Step9 from './Steps/Step9/Step9'
import Step10 from './Steps/Step10/Step10'

const ICEInfo: React.FC<ICEInfoProps> = (props: ICEInfoProps) => {
  const {
    isAddMoreContactHidden,
    iceContacts,
    isErrorExistsStep1,
    allergiesTypesData,
    otherAllergies,
    chronicDiseasesSearch,
    debouncedChronicDiseasesSearch,
    otherDiseases,
    moleculeName,
    dose,
    frequency,
    pastSurgeries,
    protheses,
    vaccinesSearch,
    debouncedVaccinesSearch,
    isSmoker,
    isDrinker,
    hasContactLens,
    isLoading,
    selectedVaccines,
    selectedChronicDiseases,
    selectedVaccinesWithData,
    setIsAddMoreContactHidden,
    setICEContacts,
    setIsErrorExistsStep1,
    setAllergiesTypesData,
    setOtherAllergies,
    setChronicDiseasesSearch,
    setSelectedChronicDiseases,
    setOtherDiseases,
    setMoleculeName,
    setDose,
    setFrequency,
    setPastSurgeries,
    setProtheses,
    setVaccinesSearch,
    setSelectedVaccines,
    setSelectedVaccinesWithData,
    handleICEContactChange,
    handleVaccineDataChange,
    handleVaccineDataRemove,
    handleBoxChange,
    handleSubmitStep1,
    handleSubmitICEInfo,
  } = useICEInfo(props)

  const steps = [
    {
      title: 'In Case Of Emergency Call',
      content: [
        (
          <Step1
            isAddMoreContactHidden={isAddMoreContactHidden}
            iceContacts={iceContacts}
            setIsAddMoreContact={setIsAddMoreContactHidden}
            setICEContacts={setICEContacts}
            setIsErrorExists={setIsErrorExistsStep1}
            onICEContactsChange={handleICEContactChange}
          />
        ) as JSX.Element,
      ],
      isCloseAllowed: true,
      isErrorExists: isErrorExistsStep1,
      onClick: () => handleSubmitStep1(),
    },
    {
      title: 'Allergies',
      isSkipAllowed: true,
      content: [
        (
          <Step2
            allergiesTypesData={allergiesTypesData}
            otherAllergies={otherAllergies}
            setAllergiesTypesData={setAllergiesTypesData}
            setOtherAllergies={setOtherAllergies}
          />
        ) as JSX.Element,
      ],
    },
    {
      title: 'Chronic Diseases',
      isSkipAllowed: true,
      content: [
        (
          <Step3
            chronicDiseasesSearch={chronicDiseasesSearch}
            debouncedChronicDiseasesSearch={debouncedChronicDiseasesSearch}
            selectedChronicDiseases={selectedChronicDiseases}
            setChronicDiseasesSearch={setChronicDiseasesSearch}
            setSelectedChronicDiseases={setSelectedChronicDiseases}
          />
        ) as JSX.Element,
      ],
    },
    {
      title: 'Other Diseases',
      isSkipAllowed: true,
      content: [
        (
          <Step4
            otherDiseases={otherDiseases}
            setOtherDiseases={setOtherDiseases}
          />
        ) as JSX.Element,
      ],
    },
    {
      title: 'Medications',
      isSkipAllowed: true,
      content: [
        (
          <Step5
            moleculeName={moleculeName}
            dose={dose}
            frequency={frequency}
            setMoleculeName={setMoleculeName}
            setDose={setDose}
            setFrequency={setFrequency}
          />
        ) as JSX.Element,
      ],
    },
    {
      title: 'Past Surgeries',
      isSkipAllowed: true,
      content: [
        (
          <Step6
            pastSurgeries={pastSurgeries}
            setPastSurgeries={setPastSurgeries}
          />
        ) as JSX.Element,
      ],
    },
    {
      title: 'Protheses and Medical Devices',
      isSkipAllowed: true,
      content: [
        (
          <Step7 protheses={protheses} setProtheses={setProtheses} />
        ) as JSX.Element,
      ],
    },
    {
      title: 'Other Information',
      content: [
        (
          <Step8
            isSmoker={isSmoker}
            isDrinker={isDrinker}
            hasContactLens={hasContactLens}
            handleBoxChange={handleBoxChange}
          />
        ) as JSX.Element,
      ],
    },
    {
      title: 'Your Vaccines',
      isSkipAllowed: true,
      content: [
        (
          <Step9
            vaccinesSearch={vaccinesSearch}
            debouncedVaccinesSearch={debouncedVaccinesSearch}
            selectedVaccines={selectedVaccines}
            selectedVaccinesWithData={selectedVaccinesWithData}
            setVaccinesSearch={setVaccinesSearch}
            setSelectedVaccines={setSelectedVaccines}
            setSelectedVaccinesWithData={setSelectedVaccinesWithData}
          />
        ) as JSX.Element,
      ],
    },
    {
      title: 'Vaccines Certificates',
      content: [
        (
          <Step10
            selectedVaccines={selectedVaccinesWithData}
            onVaccineDataChange={handleVaccineDataChange}
            onVaccineDataRemove={handleVaccineDataRemove}
          />
        ) as JSX.Element,
      ],
      showLastPageSubmit: true,
      isLoading: isLoading,
      onClick: () => handleSubmitICEInfo(),
    },
  ]

  return (
    <main>
      <div className='container'>
        <Stepper isLastStepCounted steps={steps} />
      </div>
    </main>
  )
}

export default ICEInfo
