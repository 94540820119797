import React from 'react'
import { useLogin, LoginProps } from './useLogin'
import MobileControl from 'components/Controls/MobileControl/MobileControl'
import Button from 'components/Button/Button'
import logo from 'img/logo.svg'
import styles from './Login.module.scss'

const Login: React.FC<LoginProps> = (props: LoginProps) => {
  const { errorMessage, isLoading, showErrorMessage, onChange, onSubmit } =
    useLogin(props)

  return (
    <main className={`${styles.main} py-5`}>
      <div className='container'>
        <img src={logo} width={225} height={115} loading='lazy' alt='logo' />
        <div className='row justify-content-center py-5'>
          <div className='col-md-6'>
            <h5 className={styles.welcome}>Welcome!</h5>
            <h1 className={styles.title}>Login with your phone</h1>
          </div>
        </div>
        <form onSubmit={onSubmit}>
          <div className='row justify-content-center mb-4'>
            <div className='col-md-6'>
              <MobileControl
                label='Phone Number'
                showErrorMessage={showErrorMessage}
                errorMessage={errorMessage}
                onChange={onChange}
              />
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-md-6'>
              <Button
                id='login'
                type='submit'
                variant='primary'
                text={isLoading ? 'Loading...' : 'Login'}
                disabled={isLoading}
                extraCssClasses='w-100'
              />
            </div>
          </div>
        </form>
      </div>
    </main>
  )
}

export default Login
