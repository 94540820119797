import React, { memo } from 'react'
import {
  useIncrementDecrementControl,
  IncrementDecrementControlProps,
} from './useIncrementDecrementControl'
import styles from './IncrementDecrementControl.module.scss'

const IncrementDecrementControl: React.FC<IncrementDecrementControlProps> = (
  props: IncrementDecrementControlProps
) => {
  const {
    id,
    value,
    isDisabled,
    isBtnDecrementDisabled,
    isBtnIncrementDisabled,
    handleIncrement,
    handleDecrement,
  } = useIncrementDecrementControl(props)

  return (
    <div
      id={`IncrementDecrement-${id}`}
      className={`${styles.increment_decrement_container} ${
        isDisabled ? styles.is_disabled : ''
      }`}
    >
      <button
        onClick={handleDecrement}
        className={styles.button}
        disabled={isBtnDecrementDisabled}
        aria-label='Decrement'
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='30'
          height='30'
          viewBox='0 0 30 30'
          fill='none'
          className={styles.icon}
        >
          <path
            d='M11 15L19 15'
            className={styles.path}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
        </svg>
      </button>
      <label className={styles.label}>{value}</label>
      <button
        onClick={handleIncrement}
        className={styles.button}
        disabled={isBtnIncrementDisabled}
        aria-label='Increment'
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='30'
          height='30'
          viewBox='0 0 30 30'
          fill='none'
          className={styles.icon}
        >
          <path
            d='M11 15L19 15'
            className={styles.path}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
          <path
            d='M15 11L15 19'
            className={styles.path}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
        </svg>
      </button>
    </div>
  )
}

export default memo(IncrementDecrementControl)
