import { Dispatch } from 'redux'
import {
  apiRequest,
  apiFailure,
  getReports as getReportsReducer,
  clearReportStates as clearReportStatesReducer,
} from 'reducers/report.reducer'
import { reportService } from 'services/report.service'

export const getReports = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await reportService.getReports()
    if (response) {
      dispatch(getReportsReducer({ reports: response }))
    }
  } catch (error) {
    dispatch(apiFailure())
  }
}

export const clearReportStates = () => (dispatch: Dispatch) => {
  dispatch(clearReportStatesReducer())
}
