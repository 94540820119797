import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getDiseases } from 'actions/lookups.action'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { RootState } from 'reducers/rootReducer'
import { Disease } from 'models/Diseases/IDiseases'

export interface Step3Props {
  chronicDiseasesSearch: string
  debouncedChronicDiseasesSearch: string
  selectedChronicDiseases: string[]
  setChronicDiseasesSearch: React.Dispatch<React.SetStateAction<string>>
  setSelectedChronicDiseases: React.Dispatch<React.SetStateAction<string[]>>
}

export const useStep3 = (props: Step3Props) => {
  const {
    debouncedChronicDiseasesSearch,
    selectedChronicDiseases,
    setSelectedChronicDiseases,
  } = props

  const [fileredDiseases, setFilteredDiseases] = useState<Disease[]>([])

  const dispatch = useAppDispatch()

  const diseases: Disease[] | null = useSelector(
    (state: RootState) => state.lookups.diseases
  )
  const isGetDiseasesSuccess = useSelector(
    (state: RootState) => state.lookups.isGetDiseasesSuccess
  )
  const isLoading = useSelector((state: RootState) => state.lookups.isLoading)

  const handleTagClick = (id: string, isSelected: boolean) => {
    if (isSelected) setSelectedChronicDiseases([...selectedChronicDiseases, id])
    else {
      const updatedSelectedChronicDiseases = selectedChronicDiseases.filter(
        (val) => val !== id
      )
      setSelectedChronicDiseases(updatedSelectedChronicDiseases)
    }
  }

  useEffect(() => {
    if (diseases)
      if (debouncedChronicDiseasesSearch === '') setFilteredDiseases(diseases)
      else {
        const filtered = diseases.filter((disease) =>
          disease.name.toLowerCase().includes(debouncedChronicDiseasesSearch)
        )
        setFilteredDiseases(filtered)
      }
  }, [debouncedChronicDiseasesSearch])

  useEffect(() => {
    try {
      if (!diseases || !diseases.length) dispatch(getDiseases())
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (isGetDiseasesSuccess && diseases) setFilteredDiseases(diseases)
  }, [isGetDiseasesSuccess])

  return {
    ...props,
    diseases: fileredDiseases,
    isLoading,
    handleTagClick,
  }
}
