import React, { memo } from 'react'
import { useStep9, Step9Props } from './useStep9'
import FormControl from 'components/Controls/FormControl/FormControl'
import { FormControlTypes } from 'components/Controls/FormControl/useFormControl'
import SelectableBoxControl from 'components/Controls/SelectableBoxControl/SelectableBoxControl'

const Step8: React.FC<Step9Props> = (props: Step9Props) => {
  const {
    vaccines,
    selectedVaccines,
    vaccinesSearch,
    isLoading,
    setVaccinesSearch,
    handleBoxClick,
  } = useStep9(props)

  return (
    <div>
      <div className='row'>
        <div className='col-12 mb-4'>
          <FormControl
            id='SearchVaccines'
            name='SearchVaccines'
            value={vaccinesSearch}
            type={FormControlTypes.Search}
            placeholder='Search your Vaccines'
            onChange={(e) =>
              setVaccinesSearch((e.target as HTMLInputElement).value)
            }
          />
        </div>
      </div>
      <div className='row'>
        {isLoading && !vaccines ? (
          <div className='col-12'>Loading...</div>
        ) : (
          vaccines &&
          vaccines.map((vaccine, index) => (
            <div
              key={`${vaccine.id}-${index}`}
              className='col-6 col-md-4 col-lg-3 mb-3'
            >
              <SelectableBoxControl
                id={vaccine.id}
                title={vaccine.name}
                isSelected={selectedVaccines.includes(vaccine.id)}
                isUnselectable
                onClick={handleBoxClick}
              />
            </div>
          ))
        )}
      </div>
    </div>
  )
}

export default memo(Step8)
