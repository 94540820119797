import { combineReducers } from 'redux'
import authReducer, { AuthState } from './auth.reducer'
import basicInfoReducer, { BasicInfoState } from './basicInfo.reducer'
import iceInfoReducer, { ICEInfoState } from './iceInfo.reducer'
import lookupsReducer, { LookupsState } from './lookups.reducer'
import accountReducer, { AccountState } from './account.reducer'
import reportReducer, { ReportState } from './report.reducer'

const rootReducer = combineReducers({
  auth: authReducer,
  basicInfo: basicInfoReducer,
  iceInfo: iceInfoReducer,
  lookups: lookupsReducer,
  account: accountReducer,
  report: reportReducer,
})

export type RootState = {
  auth: AuthState
  basicInfo: BasicInfoState
  iceInfo: ICEInfoState
  lookups: LookupsState
  account: AccountState
  report: ReportState
}

export default rootReducer
