const PREFIX = 'HELPLIVE_'

export function saveToLocalStorage(key: string, data: any) {
  const prefixedKey = PREFIX + key
  localStorage.setItem(prefixedKey, JSON.stringify(data))
}

export function getFromLocalStorage(key: string) {
  const prefixedKey = PREFIX + key
  const data = localStorage.getItem(prefixedKey)
  return data ? JSON.parse(data) : null
}
