export interface Step7Props {
  protheses: string
  setProtheses: React.Dispatch<React.SetStateAction<string>>
}

export const useStep7 = (props: Step7Props) => {
  return {
    ...props,
  }
}
