import React from 'react'
import { useBoutique, BoutiqueProps } from './useBoutique'
import Product from 'components/Product/Product'
import Button from 'components/Button/Button'
import styles from './Boutique.module.scss'

const Boutique: React.FC<BoutiqueProps> = (props: BoutiqueProps) => {
  const { products, totalProducts, updateProductQuantity, handleConfirm } =
    useBoutique(props)

  return (
    <main className={styles.main}>
      <div className='container my-5'>
        <div className='row mb-5'>
          <div className='col-12 text-center'>
            <h1 className={`${styles.title} mb-4`}>Boutique</h1>
            <div className={styles.note}>
              Your package includes only one item of the below
            </div>
          </div>
        </div>
        <div className='row mb-4'>
          {products &&
            products.map((product, index) => {
              const { id, imageUrl, name, price, currency } = product

              return (
                <div
                  key={`product-${id}-${index}`}
                  className='col-md-6 col-lg-4 mb-5'
                >
                  <Product
                    id={id}
                    src={imageUrl || ''}
                    name={name}
                    price={price}
                    currency={currency}
                    onQuantityChange={updateProductQuantity}
                  />
                </div>
              )
            })}
        </div>
        <div className={styles.fixed_bottom}>
          <div className='container'>
            <div className='row mb-4'>
              <div className='col-12'>
                <div className={styles.total_card}>
                  <div className={styles.items}>
                    {totalProducts.totalItems} Item
                    {totalProducts.totalItems > 1 && 's'}
                  </div>
                  <div>
                    <span className={styles.total}>Total</span>
                    <span className={styles.price_container}>
                      <sub className={styles.currency}>$</sub>
                      <span className={styles.price}>
                        {totalProducts.totalPrice}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <Button
                  id='confirm'
                  type='button'
                  variant='primary'
                  text='Confirm'
                  extraCssClasses='w-100'
                  onClick={handleConfirm}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Boutique
