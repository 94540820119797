import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useRouter, RouterProps } from './useRouter'
import PrivateRoute from 'components/PrivateRoute/PrivateRoute'
import Home from 'screens/Home/Home'
import Login from 'screens/Auth/Login/Login'
import OTP from 'screens/Auth/OTP/OTP'
import BasicInfo from 'screens/BasicInfo/BasicInfo'
import ICEInfo from 'screens/ICEInfo/ICEInfo'
import Subscription from 'screens/Subscription/Subscription'
import Boutique from 'screens/Boutique/Boutique'
import Profile from 'screens/Profile/Profile'
import ReportQRCode from 'screens/ReportQRCode/ReportQRCode'
import Transfer from 'screens/Transfer/Transfer'
import NotFound from 'screens/NotFound/NotFound'

const Router: React.FC<RouterProps> = (props: RouterProps) => {
  const { isAuthenticated } = useRouter(props)

  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route path='/Login' element={<Login />} />
        <Route path='/OTP' element={<OTP />} />

        {/* Private Routes */}
        <Route
          path='/'
          element={
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              element={<Home />}
            />
          }
        />
        <Route
          path='/BasicInfo/*'
          element={
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              element={<BasicInfo />}
            />
          }
        />
        <Route
          path='/ICEInfo/*'
          element={
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              element={<ICEInfo />}
            />
          }
        />
        <Route
          path='/Subscription/*'
          element={
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              element={<Subscription />}
            />
          }
        />
        <Route
          path='/Boutique/*'
          element={
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              element={<Boutique />}
            />
          }
        />
        <Route
          path='/Profile/:tab?'
          element={
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              element={<Profile />}
            />
          }
        />
        <Route
          path='/ReportQRCode/:reportType/:reportId'
          element={
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              element={<ReportQRCode />}
            />
          }
        />
        <Route
          path='/Transfer/*'
          element={
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              element={<Transfer />}
            />
          }
        />

        {/* Not Found */}
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
