import parsePhoneNumberFromString from 'libphonenumber-js'

export const isValidPhoneNumber = (phoneNumber: string): boolean => {
  try {
    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber)
    return !!(parsedPhoneNumber && parsedPhoneNumber.isValid())
  } catch (error) {
    return false
  }
}
