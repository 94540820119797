import { useState, useCallback, useEffect } from 'react'

export interface ProductProps {
  id: string
  src: string
  name: string
  price: number
  currency: string
  onQuantityChange?: (productId: string, quantity: number) => void
}

export const useProduct = (props: ProductProps) => {
  const { id, price, onQuantityChange } = props

  const [value, setValue] = useState(0)

  const handleQuantityChange = useCallback((newQuantity: number) => {
    if (onQuantityChange) onQuantityChange(id, newQuantity)
  }, [])

  useEffect(() => {
    handleQuantityChange(value)
  }, [value])

  return {
    ...props,
    price,
    value,
    setValue,
    handleQuantityChange,
  }
}
