import makeRequest from 'utils/makeRequest'
import { getFromLocalStorage } from 'utils/localStorage'
import { ONBOARDING_ENDPOINTS } from 'config/apiEndpoints'
import ISubmitBasicInfo from 'models/OnBoarding/ISubmitBasicInfo'
import ISubmitBasicInfoResponse from 'models/OnBoarding/ISubmitBasicInfoResponse'

export const basicInfoService = {
  submitBasicInfo: async (
    params: ISubmitBasicInfo
  ): Promise<ISubmitBasicInfoResponse> => {
    try {
      const accessToken = getFromLocalStorage('accessToken')

      const response: ISubmitBasicInfoResponse = await makeRequest(
        'POST',
        ONBOARDING_ENDPOINTS.SubmitBasicInfo,
        params,
        accessToken
      )
      return response
    } catch (error) {
      throw new Error('Submit basic info failed')
    }
  },
}
