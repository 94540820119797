import React from 'react'
import { useSubscription, SubscriptionProps } from './useSubscription'
import SubscriptionCard from 'components/Subscription/Subscription'
import SwitchControl from 'components/Controls/SwitchControl/SwitchControl'
import FeatureHead from 'components/Features/FeatureHead/FeatureHead'
import FeatureRow from 'components/Features/FeatureRow/FeatureRow'
import Button from 'components/Button/Button'
import styles from './Subscription.module.scss'

const Subscription: React.FC<SubscriptionProps> = (
  props: SubscriptionProps
) => {
  const {
    primaryPlan,
    secondaryPlan,
    familyPlanValue,
    basicPlanSelected,
    familyPlanSelected,
    featureHead,
    featureRows,
    discount,
    setDiscount,
    setFamilyPlanValue,
    handleSelectPlan,
  } = useSubscription(props)

  return (
    <main className={styles.main}>
      <div className='container my-5'>
        <div className='row mb-5'>
          <div className='col-12 text-center'>
            <h1 className={styles.title}>Subscription</h1>
          </div>
        </div>
        <div className='row mb-5'>
          <div className='col-6'>
            {primaryPlan && (
              <SubscriptionCard
                id={`BasicPlan-${primaryPlan.id}`}
                type='Basic'
                layout='Vertical'
                isSelected={basicPlanSelected}
                monthlyPrice={primaryPlan.price}
                description={primaryPlan.description}
                priceDescription={primaryPlan.priceDescription}
                minimumPurchases={primaryPlan.minimumPurchases}
                discount={discount ? +primaryPlan.discountPercentage : null}
                onClick={() => handleSelectPlan('Basic')}
              />
            )}
          </div>
          <div className='col-6'>
            {secondaryPlan && (
              <SubscriptionCard
                id={`FamilyPlan-${secondaryPlan.id}`}
                type='Family'
                layout='Vertical'
                isSelected={familyPlanSelected}
                monthlyPrice={secondaryPlan.price}
                description={secondaryPlan.description}
                priceDescription={secondaryPlan.priceDescription}
                minimumPurchases={secondaryPlan.minimumPurchases}
                discount={discount ? +secondaryPlan.discountPercentage : null}
                value={familyPlanValue}
                setValue={setFamilyPlanValue}
                onClick={() => handleSelectPlan('Family')}
              />
            )}
          </div>
        </div>
        <div className='row mb-4'>
          <div className='col-12'>
            <SwitchControl
              id='DiscountSwitch'
              name='DiscountSwitch'
              label='Activate annual plan to get 8% off!'
              checked={discount}
              setChecked={setDiscount}
            />
          </div>
        </div>
        <FeatureHead columns={featureHead} />
        {featureRows.map((row, index) => (
          <FeatureRow key={`${row[index].rowName}-${index}`} row={row} />
        ))}
        <div className='row justify-content-center mt-5'>
          <div className='col-md-6 col-lg-4 text-center'>
            <Button
              id='continue'
              type='button'
              variant='primary'
              text='Continue'
              extraCssClasses='w-100'
            />
          </div>
        </div>
      </div>
    </main>
  )
}

export default Subscription
