import { useEffect } from 'react'

export interface Step1Props {
  firstName: string
  lastName: string
  email: string
  dob: string
  gender: string
  religion: string
  setFirstName: React.Dispatch<React.SetStateAction<string>>
  setLastName: React.Dispatch<React.SetStateAction<string>>
  setEmail: React.Dispatch<React.SetStateAction<string>>
  setDob: React.Dispatch<React.SetStateAction<string>>
  setGender: React.Dispatch<React.SetStateAction<string>>
  setReligion: React.Dispatch<React.SetStateAction<string>>
  setIsErrorExists: React.Dispatch<React.SetStateAction<boolean | undefined>>
}

export const useStep1 = (props: Step1Props) => {
  const { dob, setIsErrorExists } = props

  useEffect(() => {
    if (dob) {
      const today = new Date().getTime()
      const dateOfBirth = new Date(dob).getTime()

      if (dateOfBirth > today) setIsErrorExists(true)
      else setIsErrorExists(false)
    }
  }, [dob])

  return {
    ...props,
  }
}
