import React from 'react'
import { useReports, ReportsProps } from './useReports'
import Report from 'components/Report/Report'
import styles from './Reports.module.scss'

const Reports: React.FC<ReportsProps> = (props: ReportsProps) => {
  const { personalReport, familyReports } = useReports(props)

  return (
    <div className={styles.reports_container}>
      <div className={styles.personal_report_container}>
        <h5 className={styles.report_title}>Personal Info</h5>
        {personalReport && <Report report={personalReport} />}
      </div>
      <div className={familyReports.length ? '' : 'd-none'}>
        <h5 className={styles.report_title}>Reports</h5>
        {familyReports.map((report, index) => (
          <div
            key={`family_report_${index}`}
            className={styles.family_report_container}
          >
            <Report report={report} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Reports
