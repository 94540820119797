import React, { memo } from 'react'
import { useStep1, Step1Props } from './useStep1'
import FormControl from 'components/Controls/FormControl/FormControl'
import { FormControlTypes } from 'components/Controls/FormControl/useFormControl'
import MobileControl from 'components/Controls/MobileControl/MobileControl'
import Button from 'components/Button/Button'

const Step1: React.FC<Step1Props> = (props: Step1Props) => {
  const {
    iceContacts,
    isAddMoreContactHidden,
    onICEContactsChange,
    onPhoneNumberChange,
    handleAddMore,
    handleRemove,
  } = useStep1(props)

  return (
    <div>
      {iceContacts.map((iceContact, index) => (
        <div key={`ICEContact-${index}`} className='row'>
          <div className='col-12 col-lg-6 mb-4'>
            <FormControl
              id={`ICEName-${index}`}
              name='ICEName'
              value={iceContact.name}
              type={FormControlTypes.Text}
              placeholder='Name'
              required
              onChange={(e) =>
                onICEContactsChange(
                  index,
                  'name',
                  (e.target as HTMLInputElement).value
                )
              }
            />
          </div>
          <div className='col-12 col-lg-6 mb-4'>
            <MobileControl
              label='Phone Number'
              value={iceContact.phoneNumber}
              index={index}
              onChange={onPhoneNumberChange}
            />
          </div>
        </div>
      ))}
      <div className='row mb-4'>
        <div className='col-md-4'>
          <Button
            id='AddAnother'
            type='button'
            variant='dashed'
            text='Add Another +'
            extraCssClasses={`w-100 ${isAddMoreContactHidden ? 'd-none' : ''}`}
            onClick={handleAddMore}
          />
          <Button
            id='RemoveContact'
            type='button'
            variant='secondary'
            text='Remove Second Contact'
            extraCssClasses={`w-100 ${!isAddMoreContactHidden ? 'd-none' : ''}`}
            onClick={handleRemove}
          />
        </div>
      </div>
      <div className='row mb-4'>
        <div className='col-12'>
          *We will contact the individuals you specify above in case of an
          emergency.
        </div>
      </div>
    </div>
  )
}

export default memo(Step1)
