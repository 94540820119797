import { useState } from 'react'

export interface SelectableTagControlProps {
  id: string
  title: string
  isSelected?: boolean
  onClick?: (id: string, isSelected: boolean) => void
}

export const useSelectableTagControl = (props: SelectableTagControlProps) => {
  const { isSelected, onClick } = props

  const [selected, setSelected] = useState(isSelected)

  const handleClick = (id: string) => {
    setSelected(!selected)
    if (onClick) onClick(id, !selected || false)
  }

  return {
    ...props,
    selected,
    handleClick,
  }
}
