import makeRequest from 'utils/makeRequest'
import { getFromLocalStorage } from 'utils/localStorage'
import { REPORTS_ENDPOINTS } from 'config/apiEndpoints'
import IReports from 'models/Reports/IReports'

export const reportService = {
  getReports: async (): Promise<IReports> => {
    try {
      const accessToken = getFromLocalStorage('accessToken')

      const response: IReports = await makeRequest(
        'GET',
        REPORTS_ENDPOINTS.GetReports,
        undefined,
        accessToken
      )

      return response
    } catch (error) {
      throw new Error('Get reports failed')
    }
  },
}
