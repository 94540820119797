import SSNValidationType from 'types/SSNValidationType'

export const validateSSN = (ssn: string): SSNValidationType => {
  const cleanSSN: string = ssn.replace(/-/g, '')

  if (
    cleanSSN.length !== 9 ||
    isNaN(Number(cleanSSN.slice(0, 3))) ||
    isNaN(Number(cleanSSN.slice(3, 5))) ||
    isNaN(Number(cleanSSN.slice(5)))
  ) {
    return [false, 'Invalid SSN format']
  }

  const firstThree = Number(cleanSSN.slice(0, 3))
  const middleTwo = Number(cleanSSN.slice(3, 5))
  const lastFour = Number(cleanSSN.slice(5))

  if (firstThree === 0 || firstThree === 666 || firstThree > 899) {
    return [false, 'Invalid area number']
  }

  if (middleTwo === 0) {
    return [false, 'Invalid group number']
  }

  if (lastFour === 0) {
    return [false, 'Invalid serial number']
  }

  return [true, '']
}
