import makeRequest from 'utils/makeRequest'
import { AUTH_ENDPOINTS } from 'config/apiEndpoints'
import ILoginWithPhone from 'models/Auth/LoginWithPhone/ILoginWithPhone'
import ILoginWithPhoneResponse from 'models/Auth/LoginWithPhone/ILoginWithPhoneResponse'
import ISubmitVerificationCode from 'models/Auth/Verify/ISubmitVerificationCode'
import ISubmitVerificationCodeResponse from 'models/Auth/Verify/ISubmitVerificationCodeResponse'
import IResendVerificationCode from 'models/Auth/Others/IResendVerificationCode'
import IResendVerificationCodeResponse from 'models/Auth/Others/IResendVerificationCodeResponse'
import IRefreshToken from 'models/Auth/Others/IRefreshToken'
import IRefreshTokenResponse from 'models/Auth/Others/IRefreshTokenResponse'

export const authService = {
  login: async (
    credentials: ILoginWithPhone
  ): Promise<ILoginWithPhoneResponse> => {
    try {
      const response: ILoginWithPhoneResponse = await makeRequest(
        'POST',
        AUTH_ENDPOINTS.Login.LoginWithPhone,
        credentials
      )
      return response
    } catch (error) {
      throw new Error('Login failed')
    }
  },

  submitVerificationCode: async (
    params: ISubmitVerificationCode
  ): Promise<ISubmitVerificationCodeResponse> => {
    try {
      const response: ISubmitVerificationCodeResponse = await makeRequest(
        'POST',
        AUTH_ENDPOINTS.Verify.SubmitVerificationCode,
        params
      )
      return response
    } catch (error) {
      throw new Error('Verification failed')
    }
  },

  resendVerificationCode: async (
    params: IResendVerificationCode
  ): Promise<IResendVerificationCodeResponse> => {
    try {
      const response: IResendVerificationCodeResponse = await makeRequest(
        'POST',
        AUTH_ENDPOINTS.Others.ResendVerificationCode,
        params
      )
      return response
    } catch (error) {
      throw new Error('Resend verification code failed')
    }
  },

  refreshToken: async (
    params: IRefreshToken
  ): Promise<IRefreshTokenResponse> => {
    try {
      const response: IRefreshTokenResponse = await makeRequest(
        'POST',
        AUTH_ENDPOINTS.Others.RefreshToken,
        params
      )
      return response
    } catch (error) {
      throw new Error('Refresh token failed')
    }
  },
}
