import { createSlice } from '@reduxjs/toolkit'

export interface ICEInfoState {
  isLoading: boolean
  isSubmitICEInfoSuccess: boolean
}

const initialState: ICEInfoState = {
  isLoading: false,
  isSubmitICEInfoSuccess: false,
}

const ICEInfoSlice = createSlice({
  name: 'iceInfo',
  initialState,
  reducers: {
    apiRequest: (state) => {
      state.isLoading = true
    },
    apiFailure: (state) => {
      state.isLoading = false
    },
    submitICEInfo(state) {
      state.isLoading = false
      state.isSubmitICEInfoSuccess = true
    },
    clearICEInfoStates(state) {
      state.isLoading = false
      state.isSubmitICEInfoSuccess = false
    },
  },
})

export const { apiRequest, apiFailure, submitICEInfo, clearICEInfoStates } =
  ICEInfoSlice.actions
export default ICEInfoSlice.reducer
