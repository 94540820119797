import React from 'react'
import { Link } from 'react-router-dom'
import { useHeader, HeaderProps } from './useHeader'
import Modal from 'components/Modal/Modal'
import styles from './Header.module.scss'
import defaultProfileImg from 'img/default-profile.png'
import giftIcon from 'img/icons/gift.svg'
import qrIcon from 'img/icons/qr.svg'

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const {
    isDeleteModalOpen,
    isLoading,
    fullName,
    profileImageUrl,
    handleDeleteAccountOpen,
    handleDeleteAccountCancel,
    handleDeleteAccountSubmit,
    handleLogout,
  } = useHeader(props)

  return (
    <header>
      <div className='container'>
        <div className={styles.wrapper}>
          <div className={styles.left_side}>
            <div data-bs-toggle='dropdown' aria-expanded='false'>
              <img
                src={
                  (profileImageUrl && profileImageUrl.toString()) ||
                  defaultProfileImg
                }
                className={styles.profile_image}
                width={54}
                height={54}
                alt='profile image'
                loading='lazy'
              />
            </div>
            <ul className='dropdown-menu'>
              <li>
                <Link to='/Profile' className='dropdown-item'>
                  My Profile
                </Link>
              </li>
              <li>
                <button
                  className='dropdown-item'
                  onClick={handleDeleteAccountOpen}
                >
                  Delete Account
                </button>
              </li>
              <li>
                <button className='dropdown-item' onClick={handleLogout}>
                  Logout
                </button>
              </li>
            </ul>
            <div className={styles.profile_info}>
              <span>Hello,</span>
              <span className={styles.name}>{fullName}</span>
            </div>
          </div>
          <div className={styles.right_side}>
            <div className={styles.gift_link}>
              <Link to='/Profile/refer-to-friends'>
                <img
                  src={giftIcon}
                  width={25}
                  height={25}
                  alt='gift icon'
                  loading='lazy'
                />
              </Link>
            </div>
            <Link to='/Transfer'>
              <div className={styles.qr_link}>
                <img
                  src={qrIcon}
                  width={30}
                  height={30}
                  alt='qr icon'
                  loading='lazy'
                />
              </div>
            </Link>
          </div>
        </div>
      </div>
      <Modal
        id='DeleteAccountModal'
        title='Are you sure you want to delete your account?'
        body='You will lose all your profiles permanently, if you need any help please contact support before deleting?'
        closeButtonText='Cancel'
        submitButtonText='Delete'
        isCentered
        isOpen={isDeleteModalOpen}
        isSubmitDisabled={isLoading}
        onClose={handleDeleteAccountCancel}
        onSubmit={handleDeleteAccountSubmit}
      />
    </header>
  )
}

export default Header
