import { useState, useEffect } from 'react'
import { ICEContact } from 'screens/ICEInfo/useICEInfo'

export interface Step1Props {
  isAddMoreContactHidden: boolean
  iceContacts: ICEContact[]
  setIsAddMoreContact: React.Dispatch<React.SetStateAction<boolean>>
  setICEContacts: React.Dispatch<React.SetStateAction<ICEContact[]>>
  setIsErrorExists: React.Dispatch<React.SetStateAction<boolean | undefined>>
  onICEContactsChange: (
    index: number,
    fieldName: keyof ICEContact,
    value: string
  ) => void
}

export const useStep1 = (props: Step1Props) => {
  const {
    iceContacts,
    setICEContacts,
    setIsAddMoreContact,
    setIsErrorExists,
    onICEContactsChange,
  } = props

  const [dialCodeLength, setDialCodeLength] = useState<number | undefined>(3)

  const onPhoneNumberChange = (
    phoneNumber: string,
    index?: number,
    codeLength?: number
  ) => {
    if (index || index === 0)
      onICEContactsChange(index, 'phoneNumber', phoneNumber)

    setDialCodeLength(codeLength)
  }

  const handleAddMore = () => {
    setICEContacts([...iceContacts, { name: '', phoneNumber: '' }])
    setIsAddMoreContact(true)
  }

  const handleRemove = () => {
    const updatedICEContacts = iceContacts.filter((_, i) => i !== 1)
    setICEContacts(updatedICEContacts)
    setIsAddMoreContact(false)
  }

  useEffect(() => {
    let phoneNumberError = false
    iceContacts.forEach(function (iceContact) {
      if (
        dialCodeLength &&
        iceContact.phoneNumber.length - dialCodeLength < 1
      ) {
        phoneNumberError = true
      }
    })
    setIsErrorExists(phoneNumberError)
  }, [iceContacts])

  return {
    ...props,
    onPhoneNumberChange,
    handleAddMore,
    handleRemove,
  }
}
