import { useEffect, useState } from 'react'

export interface ModalProps {
  id: string
  title: string
  body: string
  closeButtonText: string
  submitButtonText: string
  isOpen: boolean
  isSubmitDisabled?: boolean
  isCentered?: boolean
  isScrollable?: boolean
  onClose: () => void
  onSubmit: () => void
}

export const useModal = (props: ModalProps) => {
  const { isOpen, onClose } = props
  const [isFadingIn, setIsFadingIn] = useState<boolean>(false)

  const handleOverlayClick = (event: React.MouseEvent) => {
    if (event.target === event.currentTarget) {
      onClose()
    }
  }

  const handleEscapeKey = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose()
    }
  }

  const handleTransitionEnd = () => {
    if (!isOpen) {
      setIsFadingIn(false)
    } else {
      setIsFadingIn(true)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeKey, { passive: true })
    return () => {
      document.removeEventListener('keydown', handleEscapeKey)
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      setIsFadingIn(true)
    } else {
      setIsFadingIn(false)
    }
  }, [isOpen])

  return {
    ...props,
    isFadingIn,
    handleOverlayClick,
    handleTransitionEnd,
  }
}
