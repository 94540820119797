export interface SelectControlProps {
  id: string
  name: string
  label: string
  options: Option[]
  defaultValue?: string
  required?: boolean
  disabled?: boolean
  onChange?: () => void
}

export interface Option {
  Id: string
  Name: string
}

export const useSelectControl = (props: SelectControlProps) => {
  return {
    ...props,
  }
}
