import { createSlice } from '@reduxjs/toolkit'
import { Disease } from 'models/Diseases/IDiseases'
import { Vaccine } from 'models/Vaccines/IVaccines'
import { AllergyType } from 'models/Allergies/IAllergyTypes'
import IPlans from 'models/Plans/IPlans'
import Product from 'models/Products/IProducts'

export interface LookupsState {
  isLoading: boolean
  isGetDiseasesSuccess: boolean
  isGetVaccinesSuccess: boolean
  isGetProductsSuccess: boolean
  isGetPlansSuccess: boolean
  isGetAllergiesTypesSuccess: boolean
  diseases: Disease[] | null
  vaccines: Vaccine[] | null
  products: Product[] | null
  plans: IPlans | null
  allergiesTypes: AllergyType[] | null
}

const initialState: LookupsState = {
  isLoading: false,
  isGetDiseasesSuccess: false,
  isGetVaccinesSuccess: false,
  isGetProductsSuccess: false,
  isGetPlansSuccess: false,
  isGetAllergiesTypesSuccess: false,
  diseases: null,
  vaccines: null,
  products: null,
  plans: null,
  allergiesTypes: null,
}

const lookupsSlice = createSlice({
  name: 'lookups',
  initialState,
  reducers: {
    apiRequest: (state) => {
      state.isLoading = true
    },
    apiFailure: (state) => {
      state.isLoading = false
    },
    getDiseases(state, action) {
      state.isLoading = false
      state.isGetDiseasesSuccess = true
      state.diseases = action.payload.diseases
    },
    getVaccines(state, action) {
      state.isLoading = false
      state.isGetVaccinesSuccess = true
      state.vaccines = action.payload.vaccines
    },
    getProducts(state, action) {
      state.isLoading = false
      state.isGetProductsSuccess = true
      state.products = action.payload.products
    },
    getPlans(state, action) {
      state.isLoading = false
      state.isGetPlansSuccess = true
      state.plans = action.payload.plans
    },
    getAllergiesTypes(state, action) {
      state.isLoading = false
      state.isGetAllergiesTypesSuccess = true
      state.allergiesTypes = action.payload.allergies
    },
    clearLookupsStates(state) {
      state.isLoading = false
      state.isGetDiseasesSuccess = false
      state.isGetVaccinesSuccess = false
      state.isGetProductsSuccess = false
      state.isGetPlansSuccess = false
      state.isGetAllergiesTypesSuccess = false
      state.diseases = null
      state.vaccines = null
      state.products = null
      state.plans = null
      state.allergiesTypes = null
    },
  },
})

export const {
  apiRequest,
  apiFailure,
  getDiseases,
  getVaccines,
  getProducts,
  getPlans,
  getAllergiesTypes,
  clearLookupsStates,
} = lookupsSlice.actions
export default lookupsSlice.reducer
