import React, { memo } from 'react'
import { useProduct, ProductProps } from './useProduct'
import IncrementDecrementControl from 'components/Controls/IncrementDecrementControl/IncrementDecrementControl'
import styles from './Product.module.scss'

const Product: React.FC<ProductProps> = (props: ProductProps) => {
  const { id, src, name, price, currency, value, setValue } = useProduct(props)

  return (
    <div id={id} className={styles.product_card}>
      <img
        src={src}
        className={styles.img}
        width='100%'
        height={200}
        loading='lazy'
        alt={`${name} image not found`}
      />
      <div className={styles.content}>
        <span className={styles.name}>{name}</span>
        <div className={styles.price}>
          <sub>{currency}</sub>
          <span>{price}</span>
        </div>
      </div>
      <div className={styles.increment_decrement_container}>
        <IncrementDecrementControl
          id={`IncrementDecrement${id}`}
          min={0}
          max={999}
          value={value}
          setValue={setValue}
        />
      </div>
    </div>
  )
}

export default memo(Product)
