import React from 'react'
import { useBasicInfo, BasicInfoProps } from './useBasicInfo'
import Stepper from 'components/Stepper/Stepper'
import Step1 from './Steps/Step1/Step1'
import Step2 from './Steps/Step2/Step2'
import Step3 from './Steps/Step3/Step3'
import Step4 from './Steps/Step4/Step4'
import Step5 from './Steps/Step5/Step5'

const BasicInfo: React.FC<BasicInfoProps> = (props: BasicInfoProps) => {
  const {
    firstName,
    lastName,
    email,
    dob,
    gender,
    religion,
    isErrorExistsStep1,
    selectedBloodType,
    otherBloodType,
    isErrorExistsStep2,
    isAddMoreDoctorHidden,
    treatingDoctors,
    isErrorExistsStep3,
    ssName,
    ssPhoneNumber,
    ssNumber,
    ssExpiryDate,
    isErrorExistsStep4,
    isLoading,
    isSubmitBasicInfoSuccess,
    setFirstName,
    setlastName,
    setEmail,
    setDob,
    setGender,
    setReligion,
    setIsErrorExistsStep1,
    setSelectedBloodType,
    setOtherBloodType,
    setIsErrorExistsStep2,
    setIsAddMoreDoctorHidden,
    setTreatingDoctors,
    setIsErrorExistsStep3,
    setSSName,
    setSSPhoneNumber,
    setSSNumber,
    setSSExpiryDate,
    setIsErrorExistsStep4,
    handleTreatingDoctorChange,
    handleSubmitStep1,
    handleSubmitStep2,
    handleSubmitStep3,
    handleSubmitBasicInfo,
    handleSubmitLastStep,
  } = useBasicInfo(props)

  const steps = [
    {
      title: 'Personal Data',
      content: [
        (
          <Step1
            firstName={firstName}
            lastName={lastName}
            email={email}
            dob={dob}
            gender={gender}
            religion={religion}
            setFirstName={setFirstName}
            setLastName={setlastName}
            setEmail={setEmail}
            setDob={setDob}
            setGender={setGender}
            setReligion={setReligion}
            setIsErrorExists={setIsErrorExistsStep1}
          />
        ) as JSX.Element,
      ],
      isErrorExists: isErrorExistsStep1,
      onClick: () => handleSubmitStep1(),
    },
    {
      title: 'Blood Group',
      content: [
        (
          <Step2
            selectedBloodType={selectedBloodType}
            otherBloodType={otherBloodType}
            setSelectedBloodType={setSelectedBloodType}
            setOtherBloodType={setOtherBloodType}
            setIsErrorExists={setIsErrorExistsStep2}
          />
        ) as JSX.Element,
      ],
      isErrorExists: isErrorExistsStep2,
      onClick: () => handleSubmitStep2(),
    },
    {
      title: 'Treating Doctor(s)',
      content: [
        (
          <Step3
            isAddMoreDoctorHidden={isAddMoreDoctorHidden}
            treatingDoctors={treatingDoctors}
            setIsAddMoreDoctorHidden={setIsAddMoreDoctorHidden}
            setTreatingDoctors={setTreatingDoctors}
            setIsErrorExists={setIsErrorExistsStep3}
            onTreatingDoctorsChange={handleTreatingDoctorChange}
          />
        ) as JSX.Element,
      ],
      isErrorExists: isErrorExistsStep3,
      onClick: () => handleSubmitStep3(),
    },
    {
      title: 'Social Security / Insurance Details',
      content: [
        (
          <Step4
            ssName={ssName}
            ssPhoneNumber={ssPhoneNumber}
            ssNumber={ssNumber}
            ssExpiryDate={ssExpiryDate}
            setIsErrorExists={setIsErrorExistsStep4}
            setSSName={setSSName}
            setSSPhoneNumber={setSSPhoneNumber}
            setSSNumber={setSSNumber}
            setSSExpiryDate={setSSExpiryDate}
          />
        ) as JSX.Element,
      ],
      isErrorExists: isErrorExistsStep4,
      isLoading: isLoading,
      onClick: () => handleSubmitBasicInfo(),
    },
    {
      showLastPageSubmit: true,
      content: [(<Step5 />) as JSX.Element],
      onClick: () => handleSubmitLastStep(),
    },
  ]

  return (
    <main>
      <div className='container'>
        <Stepper
          isLastStepCounted={false}
          isStepSucess={isSubmitBasicInfoSuccess}
          stepToWait={4}
          steps={steps}
        />
      </div>
    </main>
  )
}

export default BasicInfo
