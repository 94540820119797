import { useState, useEffect, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { getFromLocalStorage } from 'utils/localStorage'
import { verifyCode, resendCode } from 'actions/auth.action'
import { getAccountInfo } from 'actions/account.action'
import { RootState } from 'reducers/rootReducer'
import ISubmitVerificationCode from 'models/Auth/Verify/ISubmitVerificationCode'
import IResendVerificationCode from 'models/Auth/Others/IResendVerificationCode'

export interface OTPProps {}

export const useOTP = (props: OTPProps) => {
  const [countdown, setCountdown] = useState(300) // milliseconds
  const [otpValues, setOTPValues] = useState<string>('')
  const [isResendCodeBtnDisabled, setIsResendCodeBtnDisabled] =
    useState<boolean>(true)
  const userPhoneNumber: string = getFromLocalStorage('phoneNumber')

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isLoading = useSelector((state: RootState) => state.auth.isLoading)
  const isLoadingAccountInfo = useSelector(
    (state: RootState) => state.account.isLoading
  )
  const isSubmitVerificationCodeSuccess = useSelector(
    (state: RootState) => state.auth.isSubmitVerificationCodeSuccess
  )
  const isGetAccountInfoSuccess = useSelector(
    (state: RootState) => state.account.isGetAccountInfoSuccess
  )
  const accountInfo = useSelector(
    (state: RootState) => state.account.accountInfo
  )

  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null)

  const formatTime = useCallback((seconds: number): string => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}`
  }, [])

  const handleOTPChange = (otp: string) => {
    setOTPValues(otp)
  }

  const handleResendCode = () => {
    const args: IResendVerificationCode = {
      phoneNumber: userPhoneNumber,
    }

    try {
      dispatch(resendCode(args))
      setCountdown(300)
      setIsResendCodeBtnDisabled(true)
    } catch (error) {
      console.error(error)
      setIsResendCodeBtnDisabled(false)
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const verificationCode: ISubmitVerificationCode = {
      code: otpValues,
      phoneNumber: userPhoneNumber,
    }

    try {
      dispatch(verifyCode(verificationCode))
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (countdown === 0) {
      setIsResendCodeBtnDisabled(false)

      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current)
      }
    } else {
      const interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1)
      }, 1000)

      intervalRef.current = interval
    }

    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current)
      }
    }
  }, [countdown, setIsResendCodeBtnDisabled])

  useEffect(() => {
    if (isSubmitVerificationCodeSuccess) dispatch(getAccountInfo())
  }, [isSubmitVerificationCodeSuccess])

  useEffect(() => {
    if (isGetAccountInfoSuccess && accountInfo) {
      const { isBasicInfoCompleted, isIceInfoCompleted } = accountInfo

      if (!isBasicInfoCompleted) {
        navigate('/BasicInfo')
        return
      }

      if (!isIceInfoCompleted) {
        navigate('/ICEInfo')
        return
      }

      navigate('/')
    }
  }, [isGetAccountInfoSuccess])

  return {
    ...props,
    counter: formatTime(countdown),
    userPhoneNumber,
    isResendCodeBtnDisabled,
    isLoading: isLoading || isLoadingAccountInfo,
    handleOTPChange,
    handleResendCode,
    handleSubmit,
  }
}
