import React from 'react'
import { useOTP, OTPProps } from './useOTP'
import OTPControl from 'components/Controls/OTPControl/OTPControl'
import Button from 'components/Button/Button'
import otpSVG from 'img/otp.svg'
import styles from './OTP.module.scss'

const OTP: React.FC<OTPProps> = (props: OTPProps) => {
  const {
    counter,
    userPhoneNumber,
    isResendCodeBtnDisabled,
    isLoading,
    handleOTPChange,
    handleResendCode,
    handleSubmit,
  } = useOTP(props)

  return (
    <main className={styles.main}>
      <div className='container'>
        <div className='row justify-content-center pt-5 pb-3'>
          <div className='col-md-6 text-center'>
            <h1 className={`${styles.title}`}>OTP</h1>
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-md-6 text-center'>
            <img src={otpSVG} width={211} height={205} loading='lazy' />
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-md-6 text-center my-3'>
            {`We've`} send you a code to your phone number
            <span className={styles.email}> {userPhoneNumber}</span> Please
            enter the code
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className='row justify-content-center mb-3'>
            <div className='col-md-6 d-flex justify-content-center'>
              <OTPControl handleOTPChange={handleOTPChange} />
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-md-6 text-center'>{counter}</div>
          </div>
          <div className='row justify-content-center mb-3'>
            <div className='col-md-6 text-center'>
              {`Didn't receive a code? `}
              <Button
                id='resendCode'
                type='button'
                variant='link'
                text='Resend Code'
                disabled={isResendCodeBtnDisabled}
                onClick={handleResendCode}
              />
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-md-6'>
              <Button
                id='otp'
                type='submit'
                variant='primary'
                text={isLoading ? 'Loading...' : 'Continue'}
                disabled={isLoading}
                extraCssClasses='w-100'
              />
            </div>
          </div>
        </form>
      </div>
    </main>
  )
}

export default OTP
