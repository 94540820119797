import React from 'react'
import './App.scss'
import Router from 'router/Router'

function App() {
  return (
    <div className='App'>
      <Router />
    </div>
  )
}

export default App
