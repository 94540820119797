import React from 'react'
import { usePaymentMethod, PaymentMethodProps } from './usePaymentMethod'

const PaymentMethod: React.FC<PaymentMethodProps> = (
  props: PaymentMethodProps
) => {
  const {} = usePaymentMethod(props)

  return (
    <div
      className='tab-pane fade'
      id='payment-method'
      role='tabpanel'
      aria-labelledby='payment-method-tab'
      tabIndex={0}
    >
      Payment Method Tab
    </div>
  )
}

export default PaymentMethod
