import { useState, useEffect } from 'react'

export interface SelectableBoxControlProps {
  id: string
  title: string
  isSelected?: boolean
  isUnselectable?: boolean
  onClick?: (id?: string, isSelected?: boolean) => void
}

export const useSelectableBoxControl = (props: SelectableBoxControlProps) => {
  const { isSelected, isUnselectable, onClick } = props

  const [selected, setSelected] = useState(isSelected)

  const handleClick = (id?: string) => {
    if (isUnselectable) {
      setSelected(!selected)
      if (onClick && id) onClick(id, !isSelected || false)
    } else {
      if (!selected) {
        setSelected(true)
        if (onClick) onClick()
      }
    }
  }

  useEffect(() => {
    if (typeof isSelected === 'boolean') {
      setSelected(isSelected)
    }
  }, [isSelected])

  return {
    ...props,
    selected,
    handleClick,
  }
}
