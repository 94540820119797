import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'reducers/rootReducer'
import styles from './ReferToFriends.module.scss'

export interface ReferToFriendsProps {
  tab?: string
}

export const useReferToFriends = (props: ReferToFriendsProps) => {
  const [level1Classes, setLevel1Classes] = useState<string>('')
  const [level2Classes, setLevel2Classes] = useState<string>('')
  const [level3Classes, setLevel3Classes] = useState<string>('')
  const [level4Classes, setLevel4Classes] = useState<string>('')

  const refferal = useSelector(
    (state: RootState) => state.account.accountInfo?.referral
  )

  useEffect(() => {
    const level = refferal?.level

    if (typeof level === 'number') {
      if (level === 0) {
        setLevel1Classes(`${styles.referral_item} ${styles.bg_gray_100}`)
        setLevel2Classes(`${styles.referral_item} ${styles.bg_gray_100}`)
        setLevel3Classes(`${styles.referral_item} ${styles.bg_gray_100}`)
        setLevel4Classes(`${styles.referral_item} ${styles.bg_gray_100}`)

        return
      }

      if (level === 1) {
        setLevel1Classes(
          `${styles.referral_item} ${styles.active} ${styles.bg_gray_100}`
        )
        setLevel2Classes(`${styles.referral_item} ${styles.bg_gray_100}`)
        setLevel3Classes(`${styles.referral_item} ${styles.bg_gray_100}`)
        setLevel4Classes(`${styles.referral_item} ${styles.bg_gray_100}`)

        return
      }

      if (level === 2) {
        setLevel1Classes(`${styles.referral_item} ${styles.active}`)
        setLevel2Classes(
          `${styles.referral_item} ${styles.active} ${styles.bg_gray_100} ${styles.border_bottom_0}`
        )
        setLevel3Classes(`${styles.referral_item} ${styles.bg_gray_100}`)
        setLevel4Classes(`${styles.referral_item} ${styles.bg_gray_100}`)

        return
      }

      if (level === 3) {
        setLevel1Classes(`${styles.referral_item} ${styles.active}`)
        setLevel2Classes(`${styles.referral_item} ${styles.active}`)
        setLevel3Classes(
          `${styles.referral_item} ${styles.active} ${styles.bg_gray_100} ${styles.border_bottom_0}`
        )
        setLevel4Classes(`${styles.referral_item} ${styles.bg_gray_100}`)

        return
      }

      if (level === 4) {
        setLevel1Classes(`${styles.referral_item} ${styles.active}`)
        setLevel2Classes(`${styles.referral_item} ${styles.active}`)
        setLevel3Classes(`${styles.referral_item} ${styles.active}`)
        setLevel4Classes(
          `${styles.referral_item} ${styles.active} ${styles.bg_gray_100} ${styles.border_bottom_0}`
        )

        return
      }
    }
  }, [refferal])

  return {
    ...props,
    ...refferal,
    level1Classes,
    level2Classes,
    level3Classes,
    level4Classes,
  }
}
