import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllergiesTypes } from 'actions/lookups.action'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { RootState } from 'reducers/rootReducer'
import { AllergyType } from 'models/Allergies/IAllergyTypes'
import { Allergy } from 'models/OnBoarding/ISubmitICEInfo'

export interface Step2Props {
  allergiesTypesData: Allergy[]
  otherAllergies: string
  setAllergiesTypesData: React.Dispatch<React.SetStateAction<Allergy[]>>
  setOtherAllergies: React.Dispatch<React.SetStateAction<string>>
}

export const useStep2 = (props: Step2Props) => {
  const { allergiesTypesData, setAllergiesTypesData } = props

  const [allergies, setAllergies] = useState<AllergyType[]>([])

  const dispatch = useAppDispatch()

  const allergiesTypes: AllergyType[] | null = useSelector(
    (state: RootState) => state.lookups.allergiesTypes
  )
  const isGetAllergiesTypesSuccess = useSelector(
    (state: RootState) => state.lookups.isGetAllergiesTypesSuccess
  )
  const isLoading = useSelector((state: RootState) => state.lookups.isLoading)

  const handleAllergyTypeChange = (id: string, newText: string) => {
    const updatedValuesArray = allergiesTypesData.map((item) =>
      item.allergyTypeId === id ? { ...item, text: newText } : item
    )
    setAllergiesTypesData(updatedValuesArray)
  }

  useEffect(() => {
    try {
      if (!allergiesTypes || !allergiesTypes.length)
        dispatch(getAllergiesTypes())
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (isGetAllergiesTypesSuccess && allergiesTypes)
      setAllergies(allergiesTypes)
  }, [isGetAllergiesTypesSuccess])

  useEffect(() => {
    if (
      allergiesTypes &&
      allergiesTypes.length > 0 &&
      allergiesTypesData.length === 0
    ) {
      const defaultValues = allergiesTypes.map((type) => ({
        allergyTypeId: type.id,
        text: '',
      }))
      setAllergiesTypesData(defaultValues)
    }
  }, [allergiesTypes, allergiesTypesData])

  return {
    ...props,
    isLoading,
    allergies,
    allergiesTypesData,
    setAllergiesTypesData,
    handleAllergyTypeChange,
  }
}
