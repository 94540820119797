import React, { memo } from 'react'
import { useStep5, Step5Props } from './useStep5'
import styles from './Step5.module.scss'
import checkCircleIcon from 'img/icons/check-circle.svg'

const Step5: React.FC<Step5Props> = (props: Step5Props) => {
  const {} = useStep5(props)

  return (
    <div className={styles.step5_container}>
      <img
        src={checkCircleIcon}
        height={100}
        width={100}
        alt='check icon'
        loading='lazy'
        className='mb-5'
      />
      <div className='text-center'>
        <h3 className={styles.title}>{`You're`} doing great!</h3>
        <p className={styles.paragraph}>
          Just few more questions. Your answers are important and will make a
          difference in your healthcare journey.
        </p>
      </div>
    </div>
  )
}

export default memo(Step5)
