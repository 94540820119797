import { useState, useCallback, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getProducts } from 'actions/lookups.action'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { Product } from 'models/Products/IProducts'
import { RootState } from 'reducers/rootReducer'

export interface BoutiqueProps {}

interface TotalProducts {
  totalItems: number
  totalPrice: number
}

export const useBoutique = (props: BoutiqueProps) => {
  const [quantities, setQuantities] = useState<{ [productId: string]: number }>(
    {}
  )

  const dispatch = useAppDispatch()

  const products: Product[] = useSelector(
    (state: any) => state.lookups.products
  )
  const isLoading = useSelector((state: RootState) => state.lookups.isLoading)

  const updateProductQuantity = useCallback(
    (productId: string, quantity: number) => {
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [productId]: quantity,
      }))
    },
    []
  )

  const handleConfirm = () => {}

  const totalProducts: TotalProducts = useMemo(() => {
    let items = 0
    let price = 0

    if (products)
      products.forEach((product) => {
        price += product.price * quantities[product.id]
        items += quantities[product.id]
      })

    const total = {
      totalItems: items || 0,
      totalPrice: price || 0,
    }

    return total
  }, [quantities])

  useEffect(() => {
    try {
      if (!products || !products.length) dispatch(getProducts())
    } catch (error) {
      console.error(error)
    }
  }, [])

  return {
    ...props,
    quantities,
    products,
    totalProducts,
    isLoading,
    updateProductQuantity,
    handleConfirm,
  }
}
