import React from 'react'
import { useNotFound, NotFoundProps } from './useNotFound'
import { Link } from 'react-router-dom'
import forbiddenIcon from 'img/icons/forbidden.svg'
import styles from './NotFound.module.scss'

const NotFound: React.FC<NotFoundProps> = (props: NotFoundProps) => {
  const {} = useNotFound(props)

  return (
    <main className={styles.not_found}>
      <img src={forbiddenIcon} width={150} height={150} alt='Forbidden Icon' />
      <h1 className={styles.title}>404 Not Found</h1>
      <p className={styles.text}>
        The page you are looking for could not be found.
      </p>
      <Link to='/' className={styles.link}>
        Back to home page
      </Link>
    </main>
  )
}

export default NotFound
