import { useState } from 'react'
import { copyToClipboard } from 'utils/copyToClipboard'

export interface FormControlProps {
  type: FormControlTypes
  id: string
  name: string
  placeholder: string
  value?: string | number
  defaultValue?: string
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
  rows?: number
  showErrorMessage?: boolean
  errorMessage?: string
  onChange?: (e: React.ChangeEvent) => void
  onKeyUp?: (e: React.KeyboardEvent) => void
}

export enum FormControlTypes {
  Text = 'text',
  Email = 'email',
  Password = 'password',
  Search = 'search',
  Link = 'url',
  Number = 'number',
  Date = 'date',
  Textarea = 'textarea',
}

export const useFormControl = (props: FormControlProps) => {
  const { defaultValue } = props
  const [copyLinkText, setCopyLinkText] = useState('Copy Link')

  const handleCopy = () => {
    if (defaultValue) {
      const copied = copyToClipboard(defaultValue)
      if (copied) {
        setCopyLinkText('Copied!')
        setTimeout(() => {
          setCopyLinkText('Copy link')
        }, 2000)
      }
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !(
        event.key === 'Backspace' ||
        event.key === 'Delete' ||
        event.key === 'ArrowLeft' ||
        event.key === 'ArrowRight' ||
        event.key === 'Tab' ||
        event.key === 'Enter' ||
        /^\d$/.test(event.key)
      )
    ) {
      event.preventDefault()
    }
  }

  return {
    ...props,
    copyLinkText,
    handleCopy,
    handleKeyDown,
  }
}
